import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Form, Col, Row, FormCheck } from 'react-bootstrap';
import TextBox from '../../../../components/TextBox';

export const QuestionDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 0 40px 12px;
    margin-top: 100px; // Add top margin to account for the header height


    h3 {
        margin: 0;
        padding: 0;
        font-weight: 600;
    }

    h4 {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: 300;
    }

    h5 {
        margin: 6px 0 0 0;
        padding: 0;
        font-size: 0.875em;
        font-weight: 300;
    }
`;

const CircleCheckBox = styled.div<{ $selected?: boolean; }>`
    height: 20px;
    width: 30px;
    border-radius: 50%;
    border: 1.5px solid ${props => (props.$selected ? '#303445' : '#303445')};
    background-color: ${props => (props.$selected ? '#303445' : 'transparent')};
`;

const Question: React.FC<QuestionProps & { index: number; }> = ({
    id,
    title,
    value,
    setAnswer,
    options,
    subheader,
    index,
    choice
}) => {

    const backgroundColor = useMemo(() => {
        return index % 2 === 0 ? '#DCF2F1' : '#DCF2F2';
    }, [index]);

    const handleOptionClick = (optionLabel: string) => {
        setAnswer(id, optionLabel);
    };

    return (
        <QuestionDiv style={{ backgroundColor, borderRadius: '1rem' }} className={`my-4 shadow-sm`}>
            <Form style={{ padding: '2rem' }}>
                <Row>
                    <Col md={5}>
                        <h3 className={`text-dark fw-bold p-3 text-opacity-100`} style={{ lineHeight: '1.25em' }}>
                            {title}
                        </h3>
                        {subheader && (
                            <h5 className={`fs-5 fw-medium text-dark text-opacity-75 my-3 p-3`}>{subheader}</h5>
                        )}
                    </Col>
                    <Col md={7} className={`rounded-4 bg-white p-5`}>
                        {options.map((option, key) => (
                            <Row key={key} style={{ gap: 12, marginTop: 8 }} className={`border border-2 rounded-3 p-2`}>
                                {choice === 'input' ? (
                                    <TextBox
                                        style={{ padding: '50px' }}
                                        placeholder="Write here"
                                        value={value || ''} // Bind to the correct value
                                        onChange={(text) => {
                                            setAnswer('extra_information', text.target.value);
                                        }}
                                    />
                                ) : (
                                    <FormCheck
                                        type={choice}
                                        id={`${id}-${key}`}
                                        name={id}
                                        value={option.label}
                                        checked={option.label === value}
                                        onChange={() => handleOptionClick(option.label)}
                                        label={<h4 className={`fs-5 fw-medium`}>{option.label}</h4>}
                                    />
                                )}
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Form>
        </QuestionDiv>
    );
};

export default React.memo(Question);
