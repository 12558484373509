// useUserActions.ts
import axios from "axios";
import Cookies from "js-cookie";
// import {useCSRFToken} from './auth.actions';
import useCSRFToken from "./auth.actions"
// import {useEffect, useState} from "react";

// Define the types for the tokens and user data
interface TokenResponse {
    access: string;
    refresh: string;
    user: {
        id: number;
        username: string;
        email: string;
    };
}



// Configure axios to include the CSRF token in headers
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

const baseURL = process.env.REACT_APP_BACKEND_API;
// Helper function to get the CSRF token from cookies
// export function getCSRFToken(): string | null {
//     const token =  axios.get('/users/csrf')
//     const tokenData = token.then(response => response.data)
//     const name = "csrftoken";
//     const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
//
//     // return match ? match[2] : null;
//     return tokenData;
// }


// The function should be async and return a Promise<string | null>
export async function getCSRFToken(): Promise<string | null> {
  try {
    // Await the response from the API
    const response = await axios.get(`${baseURL}/users/csrf`, {
      withCredentials: true, // Include cookies if needed for authentication
    });

    // Extract token from the response data
    const token = response.data?.csrfToken || null;

    return token;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    return null; // Return null if there's an error
  }
}

export function useUserActions(navigate: (path: string) => void) {  // Pass navigate as a parameter



    return {
        login,
        logout,
        isLoggedIn,
        refreshAccessToken,
    };

    async function login(data: { username: string; password: string }) {
        try {

            let csrfToken = await getCSRFToken();

            // let token = useCSRFToken();

            const response = await axios.post<TokenResponse>(
                `${baseURL}/users/login`,
                data,
                {
                    headers: {
                        "X-CSRFToken": csrfToken || "",
                    },
                }
            );

            if (response.data && response.data.access && response.data.refresh) {
                setUserData(response.data);
                navigate("/dashboard"); // Use navigate passed as a parameter
            } else {
                console.error("Unexpected API response format.", response.data);
            }
        } catch (error: any) {
            console.error("Login failed:", error.response ? error.response.data : error.message);
        }
    }

    function logout() {
        localStorage.removeItem("auth");
        navigate("/");
    }

    function isLoggedIn(): boolean {
        try {
            const auth = JSON.parse(localStorage.getItem("auth") || "{}");
            return !!auth.access && !!auth.user;
        } catch (error) {
            console.error("Failed to parse auth from local storage:", error);
            return false;
        }
    }

    async function refreshAccessToken() {
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
            logout();
            return;
        }

        try {
            const response = await axios.post(`${baseURL}/token/refresh`, {
                refresh: refreshToken,
            });

            if (response.data && response.data.access) {
                const authData = JSON.parse(localStorage.getItem("auth") || "{}");
                authData.access = response.data.access;
                localStorage.setItem("auth", JSON.stringify(authData));
            } else {
                console.error("Unexpected API response format for token refresh.", response.data);
                logout();
            }
        } catch (error: any) {
            console.error("Token refresh failed:", error.response ? error.response.data : error.message);
            logout();
        }
    }

    function setUserData(data: TokenResponse) {
        localStorage.setItem(
            "auth",
            JSON.stringify({
                access: data.access,
                refresh: data.refresh,
                user: data.user,
            })
        );
    }

    function getRefreshToken(): string | null {
        try {
            const auth = JSON.parse(localStorage.getItem("auth") || "{}");
            return auth.refresh || null;
        } catch (error) {
            console.error("Failed to parse auth from local storage:", error);
            return null;
        }
    }
}
