import React from "react";
import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import CosmetricsLogo from "../../assets/images/cosmetrics-logo.svg";
import { useUserActions } from "../../hooks/user.actions";
import StartQuiz from "../StartQuiz";
import './Header.css';

const Header: React.FC = () => {
    const navigate = useNavigate();
    const { isLoggedIn, logout } = useUserActions(navigate);

    const handleLogout = (): void => {
        logout();
        navigate('/login'); // Redirect to login after logout
    };

    return (
        // <Navbar className="d-flex justify-content-center w-100 p-3">
        // header - sticky header - absolute
        // <header className="d-flex flex-row mx-auto p-3 justify-content-center align-items-center rounded-5 my-2">
        //     <div className="flex items-center space-x-2">
        //         <img src={CosmetricsLogo} alt="Cosmetrics AI" height="50" />
        //     </div>
        //     <nav>
        //         <ul className="nav flex-row">
        //             <li><Link to="/features" className="nav-item nav-link ms-2 text-dark fs-5 fw-semibold">Features</Link></li>
        //             <li><Link to="/pricing" className="nav-item nav-link ms-2 text-dark fs-5 fw-semibold">Pricing</Link></li>
        //             <li><Link to="/contact" className="nav-item nav-link ms-2 text-dark fs-5 fw-semibold">Contact</Link></li>
        //         </ul>
        //     </nav>
        // </header>

        // <header className="position-fixed header-absolute">
        // {/* <header className="d-flex  p-3 justify-content-evenly center align-items-centerx my-2 bg-warning"> */ }
        <header className={`mb-5 position-relative`}>
            <nav className={`navbar navbar-expand-xl`}>
                <div className={`bg-warning`}>
                    <Navbar.Brand href="/">
                        <img src={CosmetricsLogo} alt="Cosmetrics AI" height="50" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Col>
                            <Nav className="me-auto text-bolder fw-bolder fs-6 d-flex justify-content-evenly">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/about">About</Nav.Link>
                                <Nav.Link href="/how">How it works</Nav.Link>
                            </Nav>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Nav>
                                {isLoggedIn() ? (
                                    <Nav.Link
                                        href="/dashboard"
                                        className="fw-bolder fs-6 rounded-4 mx-2"
                                    >
                                        Dashboard
                                    </Nav.Link>
                                ) : (
                                    <Button
                                        variant="outline-dark"
                                        className="fw-bolder fs-6 rounded-4 mx-2"
                                        onClick={() => navigate('/login')}
                                    >
                                        Login
                                    </Button>
                                )}
                                {isLoggedIn() && (
                                    <Button
                                        variant="warning"
                                        className="fw-bolder fs-6 rounded-4 mx-2"
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </Button>
                                )}
                                <StartQuiz selectedColor="dark" />
                            </Nav>
                        </Col>
                    </Navbar.Collapse>
                </div>
            </nav>
        </header>
    );
};

export default Header;
