import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface CustomProgressBarProps {
    value: number;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({ value }) => {
    // Function to determine the variant based on the value
    const getVariant = (value: number): 'warning' | 'primary' | 'success' => {
        if (value < 30) return 'warning';  // red for low progress
        if (value < 70) return 'primary'; // yellow for mid progress
        return 'success';                 // green for high progress
    };

    return (
        <ProgressBar
            now={value}
            label={`${value}%`}
            variant={getVariant(value)} // dynamically set the variant
            className={`my-1 text-${getVariant(value)}`} // dynamic text color based on the variant

            style={{
                height: '1.5rem',
                fontSize: '1.15rem',
                fontWeight: '700',
            }}
        />
    );
};

export default CustomProgressBar;
