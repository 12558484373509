import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Form, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useUserActions } from "../../hooks/user.actions";
import Layout from "../../components/Layout";

// Define the type for the registration response
interface RegisterResponse {
    success: boolean;
    message: string;
}

const Register: React.FC = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useUserActions(navigate);
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const baseURL = process.env.REACT_APP_BACKEND_API;

    const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            // Make a POST request to the Django register API
            const response = await axios.post<RegisterResponse>(`${baseURL}/users/register`, {
                username,
                email,
                password,
            });

            if (response.data.success) {
                setMessage("Registration successful!");
                // Clear the form or redirect to login page
                setUsername("");
                setEmail("");
                setPassword("");
                navigate("/");
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            console.error("Registration error:", error);
            setMessage("An error occurred while registering.");
        }
    };


    useEffect(() => {
        if (isLoggedIn()) {
            console.log("Sign up should not be visible to me...I am logged in");
            navigate('/dashboard');
        }
    }, [isLoggedIn, navigate]);

    return (
        <Layout>
            {/*// <Container className="d-flex justify-content-center align-items-center vh-100">*/}
            {/*// <Container className="d-flex justify-content-center align-items-center vh-100">*/}
            <Row className="d-flex justify-content-center align-items-center w-100">
                <Col> {/* Adjusted column width for larger card */}
                    <Card className="p-4 shadow-sm"> {/* Added extra padding and shadow */}
                        <Card.Body>
                            <h2 className="text-center mb-4 text-dark fw-bolder">Register</h2>
                            <Form onSubmit={handleRegister}>
                                <Form.Group controlId="formUsername" className="mb-3">
                                    <Form.Label className={`fw-bold`}>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label className={`fw-bold`}>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label className={`fw-bold`}>Password</Form.Label>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter your password"
                                        className={`text-primary`}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Show Password"
                                        className={`fw-medium`}
                                        checked={showPassword}
                                        onChange={() => setShowPassword(!showPassword)}
                                    />
                                </Form.Group>

                                <Button variant="primary" size="lg" type="submit" className="w-100 fw-bolder">
                                    Register
                                </Button>

                                <div className="text-center mt-3">
                                    Already have an account? <a href="/login">Login Here</a>
                                </div>
                            </Form>

                            {message && (
                                <Alert variant={message.includes("successful") ? "success" : "danger"} className="mt-3">
                                    {message}
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/*// </Container>*/}
        </Layout>
    );
};

export default Register;
