'use client';
import { Link } from 'react-router-dom';
// internal
import logo from '../../assets/images/cosmetrics-logo.svg';
import SocialLinks from '../SocialLinks';

import { Col, Image, Nav, Row } from 'react-bootstrap';
import { Facebook, X } from "iconoir-react";


const widget = [
    {
        col: '2',
        title: 'Quick Link',
        lists: [
            { link: '/', title: 'Home' },
            { link: 'about', title: 'About' },
            { link: 'services', title: 'Service' },
            { link: '#', title: 'Testimonials' },
            { link: 'blog-grid', title: 'News' },
            { link: 'contact', title: 'Contact' }
        ]
    },
    {
        col: '3',
        title: 'Resources',
        lists: [
            { link: 'job', title: 'Careers' },
            { link: 'job', title: 'Job Post' },
            { link: 'portfolio-details', title: 'Case Studies' },
            { link: 'help', title: 'Help' },
            { link: 'register', title: 'Register' },
            { link: '404', title: 'Error 404' }
        ]
    },
    {
        col: '4',
        title: 'Downloads',
        app_content: true
    }
];

const Footer = () => {

    const currentYear: number = new Date().getFullYear();
    //  flex-shrink: 0;
    return (
        <Row className={`text-dark my-3 p-3 text-center w-100`}>
            <Col>
                <p className={`fw-bolder fs-5`}>Cosmetrics AI &copy; {' '} 2023 - {currentYear}</p>
            </Col>
            <Col className="d-flex justify-content-evenly">
                <Row>
                    <SocialLinks />
                    {/* <Nav>
                        <ul className="nav flex-row">
                            <li className="nav-item nav-link ms-2 text-dark fs-5 fw-semibold">
                                <Link to="mailto:cosmetrics@gmail.com" className={`text-decoration-none text-dark fw-bold text-opacity-50`}>Contact Us</Link>
                            </li>
                            <li className="nav-item nav-link ms-2 text-dark fs-5 fw-semibold">
                            </li>     </ul>
                    </Nav> */}
                </Row>
            </Col>
            {/*</Row>*/}
            {/*<footer className={`p-3 bg-light w-100 position-sticky fixed-bottom mt-5`}>*/}
            {/*    <div className='footer__area' data-bg-color='footer-bg'>*/}
            {/*        <div className='footer__top'>*/}
            {/*            <div className='container'>*/}
            {/*                <div className='row'>*/}
            {/*                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'>*/}
            {/*                        <div className='footer__widget mb-50 footer-col-1'>*/}
            {/*                            <div className='footer__logo'>*/}
            {/*                                <Link to='/'>*/}
            {/*                                    <Image src={logo} alt='logo'/>*/}
            {/*                                </Link>*/}
            {/*                            </div>*/}
            {/*                            <div className='footer__widget-content'>*/}
            {/*                                <div className='footer__info'>*/}
            {/*                                    <h3 className={`text-black fw-bolder`}>*/}
            {/*                                        {' '}*/}
            {/*                                        London Office*/}
            {/*                                    </h3>*/}
            {/*                                    <p>*/}
            {/*                                        Common Address <br/>*/}
            {/*                                    </p>*/}
            {/*                                    <div className='footer__contact'>*/}
            {/*                                        <div className='footer__contact-call'>*/}
            {/*                <span>*/}
            {/*                  <Link to='tel:442000711111'>*/}
            {/*                    <i className='fa-solid fa-phone'></i> ++44*/}
            {/*                    2000711111*/}
            {/*                  </Link>*/}
            {/*                </span>*/}
            {/*                                        </div>*/}
            {/*                                        <div className='footer__contact-mail'>*/}
            {/*                <span>*/}
            {/*                  <Link to='mailto:comms@cosmetricsai.com'>*/}
            {/*                    comms @cosmetricsai.com*/}
            {/*                  </Link>*/}
            {/*                </span>*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className='footer__social'>*/}
            {/*                                        <SocialLinks/>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    {widget.map((item, i) => (*/}
            {/*                        <div*/}
            {/*                            key={i}*/}
            {/*                            className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'*/}
            {/*                        >*/}
            {/*                            Footer Widget WIP*/}
            {/*                        </div>*/}
            {/*                    ))}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className='p-3 bg-black text-white'>*/}
            {/*            <div className='container fw-bold'>*/}
            {/*                &copy; Cosmetrics AI {currentYear} {' '} All Copyrights <SocialLinks/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</footer>*/}

        </Row >
    );
};

export default Footer;
