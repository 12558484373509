import {RowSpaced} from '../../../components/Row';
// import ProgressBar from '../../../components/ProgressBar';
import styled from 'styled-components';
import {Card, Row, Col, ProgressBar} from 'react-bootstrap';
import CustomProgressBar from "../../../components/CustomProgressBar";

const Text = styled.span`
    font-size: 1em;
    font-weight: 400;
    width: 100px;
`;

interface Props {
    label: string;
    value: number;
}

const HairScore: React.FC<Props> = ({
                                        label,
                                        value,
                                    }) => {

    return (
        <Row>
            <Col>

                <h2 className={`fs-5 fw-normal medium mt-2 text-dark`}>{label}</h2>
                <CustomProgressBar value={value} />
            </Col>
        </Row>
    );
};

export default HairScore;
