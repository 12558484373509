import PhotoStandards from "../../../../components/PhotoStandards";
import {Button, Container, Row, Col} from "react-bootstrap";


// Main Component
interface Props {
    onStartQuiz: () => void;
}

const Landing: React.FC<Props> = ({onStartQuiz}) => {
    return (
        <Row className={`p-1`}>
            <Row className="justify-content-center align-items mt-5 p-5 lead" fluid>
                <Col md={4}>
                    <h1 className={`display-3 text-dark d-flex justify-content-start align-content-start text-opacity-50`}
                        style={{fontWeight: '800'}}>Free Hair Diagnostic</h1>
                </Col>
                <Col md={8}>
                    <p>
                        Say bye bye to trial and error! When complete this system will be able to match users
                        to the cleanest ingredients and the most effective products sold in the UK/Europe.
                    </p>
                    <p>
                        We need your responses to test and further develop this life changing technology.
                        Your input will make sure it is accurate and useful for everyone.

                    </p>
                    <p><span className={`fw-bolder text-primary fs-6 my-2`}>**Please be as
                        truthful as possible in your responses as your answers will affect your product
                        recommendation.</span></p>

                </Col>
                <Row>

                </Row>
                <Button
                    variant={`dark`}
                    size={"lg"}
                    className="shadow-sm fw-bolder justify-content-center w-25"
                    onClick={() => {
                        onStartQuiz && onStartQuiz();
                    }}
                >
                    Get Started
                </Button>

            </Row>
            <Row className="justify-content-center align-items-start my-3 bg-white rounded-5 p-3" fluid>
                <Col xs={12} md={10} className={`vw-100`}>
                    <PhotoStandards/>
                </Col>
            </Row>
        </Row>
    );
};

export default Landing;
