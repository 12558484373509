import styled from "styled-components";
import Button from "../../../../components/Button";
import React from "react";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 10001;

    button {
        background-color: #78D3F7;
    }
`

interface Props {
    buttonLabel?: string;
    onButtonPress?: (() => void);
}

const BlurOverlay: React.FC<Props> = ({
    buttonLabel,
    onButtonPress,
}) => {
    return (
        <Container>
            {onButtonPress &&
                <Button onClick={() => { onButtonPress(); }}>{buttonLabel}</Button>
            }
        </Container>
    )
};

export default BlurOverlay;