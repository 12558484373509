import React from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ColorVariant { //options include primary secondary warning outline-primary etc
    selectedColor: string;
}

const StartQuiz: React.FC<ColorVariant> = ({ selectedColor }) => {
    const navigate = useNavigate();
    return (
        <Col className={`my-1`}>
            <Button
                variant={selectedColor}
                className={`fw-bolder fs-6 rounded-4`}
                onClick={() => {
                    navigate('/quiz');
                }}>

                Start Quiz
            </Button>
        </Col>
    );
};

export default StartQuiz;