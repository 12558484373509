import React from 'react';
import { Card, Carousel, Col, Container, ProgressBar, Row } from 'react-bootstrap';

const ProcessCarousel: React.FC = () => {
    const steps = [
        {
            title: "Step 1: Take the Quiz",
            description: "Complete the questionnaire."
        },
        { title: "Step 2: Analysis", description: "We analyze the pictures and questionnaire." },
        { title: "Step 3: Recommendation", description: "We recommend products specific to your hair." },
        { title: "Step 4: Apply & Repeat", description: "Apply the recommendations and repeat the steps." }
    ];

    return (
        <Container className={`my-2`}>
            <Row className={`shadow-sm`}>
                <Col className={`col-3 bg-black rounded-start-4 text-center`}>
                    <h2 className={`text-white position-relative top-50 start-50 translate-middle `}
                        style={{ fontSize: '1.75rem', fontWeight: '700' }}>in 4 <br />steps</h2>
                </Col>
                <Col className={`col-9 rounded-end-4 p-2 bg-white warning`}>
                    <Carousel interval={3000} controls={false} indicators={false}>
                        {steps.map((stage, index) => (
                            <Carousel.Item>

                                <div key={index} className="text-left">
                                    <h4 className={`fw-bolder text-dark text-opacity-100`}>{stage.title} </h4>
                                    <p className={`lead fw-medium fs-6`}>{stage.description}</p>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>


                </Col>
            </Row>
        </Container>
    );
};

export default ProcessCarousel;
