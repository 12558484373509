import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Navbar, Nav, Image } from 'react-bootstrap';
import styled from 'styled-components';
import {
    DashboardDots,
    Facebook,
    LogIn,
    LogOut,
    MapsArrowDiagonal,
    X
} from 'iconoir-react';

// Import your SVG and image assets here
import CosmetricsLogo from '../../assets/images/cosmetrics-logo.svg';
import Quiz from '../../assets/images/take-quiz.svg';
import UploadPhotos from '../../assets/images/upload-photos.svg';
import HairSuggestions from '../../assets/images/hair-suggestions.svg';
import PinkBubble from '../../assets/images/pink-bubble.svg';
import { useUserActions } from "../../hooks/user.actions";
import ComingSoon from "../ComingSoon";
import ComingSoonPage from "../ComingSoon";
import EmailCapture from "../EmailCapture";
import SocialLinks from "../../components/SocialLinks";
import Footer from "../../components/Footer";
import LandingHeroBanner from '../../components/Banners';
import StartQuiz from "../../components/StartQuiz";
import Layout from "../../components/Layout";
import FileUpload from '../../components/FileUpload';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(to bottom, #A9AFEA 0%, #DE90C4 100%);
`;

const MainContent = styled.main`
    flex: 1 0 auto;
`;

const StyledNavbar = styled(Navbar)`
    background-color: #DCF2F1;
    //background-color: #FCFCFC;
`;

const StyledSections = styled.section`
    padding: 5% 0;
    overflow: hidden;
`;

const TransparentCard = styled.div`
    background: rgba(252, 252, 252, 0.1);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 20px;
    color: #FFFFFF;
    text-align: center;
`;

const StyledImage = styled(Image)`
    max-width: 100%;
    height: auto;
    z-index: 1000;
`;

const CircleImage = styled(Image)`
    width: 120px;
    height: 120px;
    border-radius: 50%;
`;

const StyledFooter = styled.footer`
    background-color: #FFFFFF;
    padding: 20px 0;
    color: #303445;
    flex-shrink: 0;
`;

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const { isLoggedIn, logout } = useUserActions(navigate);

    useEffect(() => {
        if (!isLoggedIn()) {
            // Redirect to login if not logged in
            // logout()
            // TODO: stay on page until user clicks login -  navigate('/');
            console.log('do nothing');
            return;
        } else {
            setIsUserLoggedIn(true);
            navigate('/');
        }
    }, [isUserLoggedIn, navigate]);

    return (

        <Layout>
            <Container className={`mt-5 mb-5`}>
                <Row className={`d-flex justify-content-center align-items-center`}>
                    <LandingHeroBanner />
                </Row>

                <Row className={`bg-light rounded mt-5 mb-5 p-3 `}>
                    {/*<Row className="align-items-center">*/}
                    <Col md={6} lg={6} className={`lead fw-medium`}>
                        <h3 className={`display-6 fw-bold text-dark text-opacity-50`}>Get Recommendations</h3>
                        <p>Your curly or textured hair is one of a kind and requires an individual approach for
                            daily maintenance.</p>
                        <p>Our process uses artificial Intelligence technology to provide you with advanced
                            hair, health and lifestyle insights.</p>
                        <p>You will learn about your hair and its distinct traits and receive instant product
                            recommendations based on your unique hair type.</p>
                        <p>Uncover great products that work for your hair now!</p>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col xs={4}>
                                <CircleImage src={Quiz} alt="Take the Quiz" />
                                <p>Take the Quiz</p>
                            </Col>
                            <Col xs={4}>
                                <CircleImage src={UploadPhotos} alt="Upload Pictures" />
                                <p>Upload Pictures</p>
                            </Col>
                            <Col xs={4}>
                                <CircleImage src={HairSuggestions} alt="Get Hair Suggestions" />
                                <p>Get Hair Suggestions</p>
                            </Col>
                        </Row>
                        <Row className={`mt-3`}>
                            <StartQuiz selectedColor={`dark`} />
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Layout>

    )
        ;
};

export default Home;