import { QuizButtonFooter } from "../../screens/Quiz/style";
import { Button, Col, Container, Row } from "react-bootstrap";
import React from "react";
import { ArrowLeft, ArrowRight } from "iconoir-react";


const QuizNav: React.FC<QuizPageProps> = ({ onAction }) => {
    return (
        <Container className={`position-relative xsticky-bottom bottom-0 end-0 my-3`} >
            <Row
                className={`d-flex justify-content-center bg-transparent`}>
                <Col className={`d-flex justify-content-center`}>
                    <Button
                        variant={`outline-secondary`}
                        size={`lg`}
                        className={`rounded-3 fw-bold w-50 shadow-sm`}
                        onClick={() => {
                            onAction && onAction('back');
                        }}><ArrowLeft /> Back</Button>
                </Col>
                <Col className={`d-flex justify-content-center`}>
                    <Button
                        variant={`dark`}
                        size={`lg`}
                        className={`rounded-3 fw-bold w-50 shadow-sm`}
                        onClick={() => {
                            onAction && onAction('next');
                        }}>Next {' '}<ArrowRight /></Button>
                </Col>
            </Row>
        </Container>

    );
};

export default QuizNav;