import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image,  } from 'react-bootstrap';


function NotFound() {
  return (
    <div className={`not-found p-5`}>
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <Row className="justify-content-center">
          <Col md={6}>
            <Image
              src={require('../../assets/images/404_3.jpg')}
              alt="404-Missing-Content"
              className={`rounded-5 shadow-lg`}
              fluid
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md={8}>
            <h1 className="text-center fw-bolder text-black-50 display-2">Not hairy!</h1>
            <p className="text-center lead fs-3">
              The page you are looking for might be removed or is temporarily unavailable.
            </p>
            <p className="text-center fw-medium fs-5 bg-dark p-3 rounded-5 text-warning">
              <Link to="/" className="text-decoration-none text-white">Home</Link>{' /  '}
              <Link to="/quiz" className="text-decoration-none text-white">Quiz</Link>{' /  '}
              <Link to="/dashboard" className="text-decoration-none text-white">Dashboard</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NotFound;
