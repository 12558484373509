import styled from "styled-components";

const Row = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
`;

export const RowSpaced = styled(Row)`
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
`;

export default Row;