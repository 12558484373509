import Button from "../../../../components/Button";
import { QuizButtonFooter, QuizPageRoot, TextBox } from "../../style";
import styled from "styled-components";
import QuizNav from "../../../../components/QuizNavigation";
import React from "react";

const PageRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7rem;
    position: relative;
    height: 100vh;
    width: 150px;
    border-radius: 15px;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    div {
        height: 100%;
        border-radius: 8px;
    }
`;


const ExtraInformation: React.FC<QuizPageProps> = ({
    answers,
    setAnswer,
    onAction,
}) => {

    return (
        <QuizPageRoot style={{ padding: '100px', marginTop: '2rem' }}>
            <TextBox
                style={{ padding: "50px" }}
                placeholder="Write here"
                value={answers['extra_information']}
                onChange={(text) => {
                    setAnswer('extra_information', text.target.value);
                }} />

            <QuizNav onAction={onAction} answers={answers} setAnswer={setAnswer} />
        </QuizPageRoot>
    );
};

export default ExtraInformation;