import React, { useState, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useUserActions } from "../../hooks/user.actions";
import Layout from "../../components/Layout";

interface LoginState {
    username: string;
    password: string;
}

const Login = () => {
    const navigate = useNavigate();
    const { login } = useUserActions(navigate);
    const [formData, setFormData] = useState<LoginState>({ username: '', password: '' });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [alert, setAlert] = useState<{ message: string; variant: 'success' | 'danger'; } | null>(null);
    const [message, setMessage] = useState<string>("");

    // Handle form input changes
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    // Handle form submission for login
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await login(formData);
            setAlert({ message: `Welcome ${formData.username}, login successful!`, variant: 'success' });
            setMessage(`Welcome ${formData.username}, login successful!`);
            navigate('/dashboard'); // Navigate to the dashboard on successful login
        } catch (err: any) {
            setAlert({ message: err.message || "An error occurred while logging in.", variant: 'danger' });
            setMessage(err.message);
            console.error("Login Error: ", err);
        }
    };

    return (
        <Layout>
            <Row className="d-flex justify-content-center me-auto h-100" fluid>
                {/*<Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} sm={{ span: 12 }}>*/}
                <Col>
                    <Card className="p-2 shadow-sm w-100">
                        <Card.Body>
                            <h2 className="text-center mb-4 fw-bolder text-dark">Login</h2>
                            {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                            <Form onSubmit={handleLogin}>
                                <Form.Group controlId="formUsername" className="mb-3">
                                    <Form.Label className="fw-bold">Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label className="fw-bold">Password</Form.Label>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter your password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formShowPassword" className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Show Password"
                                        className="fw-medium"
                                        checked={showPassword}
                                        onChange={() => setShowPassword(!showPassword)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100 fw-bolder">
                                    Login
                                </Button>
                                <div className="mt-3 text-center">
                                    New user? <Link to="/signup" className="fw-bold">Signup Here</Link> for more
                                    features.
                                </div>
                            </Form>
                            {message && (
                                <Alert variant={message.includes("successful") ? "success" : "danger"} className="mt-3">
                                    {message}
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default Login;
