import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Dashboard from '../../../routes/Dashboard';
import {DashboardDots} from 'iconoir-react';
import ReportScores from '../../../components/Api/HairScoresData';
import GetUserReply from "../../../components/Api/GetUserReply";

import {Badge, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container,Image, Row} from 'react-bootstrap'
import {useUserActions} from "../../../hooks/user.actions";
import './home.css';


const CircleImage = styled.img`
    padding: 10px;
    border-radius: 50px;
    max-width: 5rem;
`;

const Home = () => {
    const navigate = useNavigate()
    const {isLoggedIn} = useUserActions(navigate);
    const [username, setUsername] = useState<string | null>(null);
    const [userId, setUserId] = useState<number>();
    const [quizComplete, setQuizComplete] = useState<boolean>(false);
    const [user, setUser] = useState({})

    // const userActions = useUserActions();
    const [loggedInUser, setLoggedInUser] = useState<boolean>(false);

    function capitalizeFirstLetter(rawString: string) {
        return rawString.charAt(0).toUpperCase() + rawString.slice(1);
    }
    useEffect(() => {
        if (!isLoggedIn()) {
            // Redirect to login if not logged in
            navigate('/login');
        } else {
            const details = localStorage.getItem('auth') || ""
            if (details) {
                setLoggedInUser(true)
                const data = JSON.parse(details)
                const currentUser = data["user"]
                setUserId(currentUser["id"])
                setUsername(capitalizeFirstLetter(currentUser["username"]))
            }
        }
    }, [isLoggedIn, navigate])

    return (
        <Dashboard>
            <Container className='bg-light'>
                <Col>
                    <h1 style={{
                        fontSize: '1.75rem',
                        fontWeight: '500',
                        marginBottom: '2rem',
                        color: "#201E43",
                        letterSpacing: 2
                    }}>
                        <DashboardDots style={{fontSize: '2rem', color: '#180161'}}/> Your Hair Dashboard</h1>
                    <h2 style={{color: '#180161'}}
                        className={`display-5 fw-medium text-primary`}> Welcome {username}! </h2>
                    <p style={{
                        fontSize: '1.15rem',
                        fontWeight: '500',
                        marginTop: '25px',
                        marginBottom: '25px',
                        color: 'gray'
                    }}>
                        Find out insights about how to care for your hair below. These are based on your most recent
                        diagnostic test.
                    </p>
                </Col>

                <Row className='my-3'>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">
                            <h1 className={`display-5 text-dark`}>Hair Score</h1>
                            <Col>
                                {userId !== undefined ? (
                                    <ReportScores userId={userId}/>
                                ) : (
                                    <p>Loading...</p> // You can display a loading message or a placeholder component
                                )}

                            </Col>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">
                            <h1 className={`display-5 text-dark`}><CircleImage src={require('../../../assets/images/washing.png')}
                                                                               alt="Washing Routine"/>Routines</h1>


                            <Card.Title className={`fw-bolder fs-3`}>Regular Washing</Card.Title>

                            <Card.Body className={`lead`}>
                                Dial down the temperature of your shower to lukewarm water when you apply
                                shampoo
                                and to cool water when
                                you apply conditioner. This can significantly reduce breakage and split
                                ends.
                            </Card.Body>
                            <p className={`text-muted`}>
                                Tip: Weekly cleaning and conditioning is a critical step in healthy hair
                                maintenance.
                            </p>
                        </Card>
                    </Col>
                </Row>
                {/*Hair ID section*/}
                <Row className={`my-5`}>

                    <h1 className="display-3 fw-bolder text-dark text-opacity-25">Hair ID</h1>
                </Row>
                <Row>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column m-3">
                            <CardImg src={require('../../../assets/images/type-4c-diagram.png')}
                                     alt="Hair Type"
                                     variant="top"
                                     style={{padding: '2rem', borderRadius: '50%'}}
                            />
                            <CardBody>
                                <CardTitle style={{fontSize: '1.5rem', fontWeight: '600', color: 'black'}}>Hair
                                    Type:
                                    {userId !== undefined ? (
                                        <GetUserReply userId={userId} questionId={6}/>
                                    ) : (
                                        <p>Loading...</p>
                                    )}

                                </CardTitle>
                                <CardText className={`lead`}>
                                    Tight, springy 'Z' shaped ringlets, prone to shrinkage, can shrink up to 75% of
                                    their length.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">
                            <Card.Img src={require('../../../assets/images/density-low.png')}
                                      alt="Hair Density" variant="top"
                                      style={{padding: '2rem'}}
                            />
                            <Card.Body>

                                <Card.Title
                                    style={{fontSize: '1.5rem', fontWeight: '600'}}>Density: {userId !== undefined ? (
                                    <GetUserReply userId={userId} questionId={9}/>
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </Card.Title>

                                <CardText className={`lead`}>
                                    Fewer strands per inch, may seem thin, sparse,
                                    lack
                                    volume, scalp more visible. </CardText>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">
                            <Card.Img src={require('../../../assets/images/texture-thin.png')}
                                      alt="Hair Texture" variant="top"
                                      style={{padding: '2rem'}}
                            />
                            <Card.Body>
                                <Card.Title style={{fontSize: '1.5rem', fontWeight: '600'}}>Texture:
                                    Thin/Fine</Card.Title>

                                <CardText className={`lead`}>Lightweight strands, may lack volume, prone to
                                    breakage, needs special care.</CardText>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className={`my-3`}>
                        <Card className="custom-card h-100 d-flex flex-column">
                            <Image src={require('../../../assets/images/porosity-high.png')}
                                      alt="Hair Porosity"
                                      style={{padding: '3rem'}}

                            />
                            <Card.Body>
                                <Card.Title style={{fontSize: '1.5rem', fontWeight: '600'}}>Porosity:
                                    High</Card.Title>

                                <CardText className={`lead`}>Cuticles raised, absorbs moisture quickly,
                                    prone to
                                    dryness, frizz, breakage.</CardText>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={`my-5`}>
                    <h1 className="display-3 fw-bolder text-dark text-opacity-25">Hair Objectives</h1>
                </Row>
                <Row>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">

                            <Col>
                                <h1 className={`display-5 text-dark`}>  <CircleImage src={require('../../../assets/images/split-ends.png')} alt="Split Ends"/>Issues</h1>
                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Split ends (hair breakage)</h4>
                                <p>
                                    Split ends are caused by a number of reasons including overuse of heat,
                                    excessive
                                    styling, chemical
                                    treatments, lack of sealing those ends, and not trimming when it's time.
                                </p>
                                <p>They cause our hair to look frizzy and damaged.</p>
                            </Col>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">

                            <Col>
                                <h1 className={`display-5 text-dark`}><CircleImage src={require('../../../assets/images/goals.png')} alt="Goals"/> Goals</h1>

                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Ultra clean hair & scalp</h4>
                                <p>
                                    Curls & coils are often stuck under layers of dirt, oil, product, and mineral
                                    build-up. Rather than
                                    lacking protein or moisture, they may need a deep clean that allows for a fresh
                                    start.
                                </p>
                                <p>
                                    Signs that it's time for a clean slate include your hair looking less curly,
                                    less
                                    shiny, less bouncy.
                                    Your Holy Grail products stop working, or dandruff-like particles start to
                                    appear on
                                    your hair and scalp.
                                </p>
                            </Col>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">

                            <Col>
                                <h1 className={`display-5 text-dark`}><CircleImage src={require('../../../assets/images/split-ends.png')} alt="Split Ends"/>Insight</h1>
                                <p>
                                    Avoid products with high resin contents, heavy oils, and non-water-soluble
                                    silicones. Instead, opt for
                                    water-soluble ingredients, proteins, amino acids, essential oils, and
                                    moisturizers.
                                </p>
                                <p>To make your hair stronger, use products with
                                    better-compatible ingredients.</p>
                            </Col>
                        </Card>
                    </Col>
                </Row>
                <Row className={`my-5`}>
                    <h1 className="display-3 fw-bolder text-dark text-opacity-25">Scalp</h1>
                </Row>
                <Row>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">

                            <h1 className={`display-6 text-dark`}>  <CircleImage src={require('../../../assets/images/sebum-production.png')}
                            alt="Sebum Production"/>Sebum Production</h1>
                            <h4 style={{
                                fontSize: '1.35rem',
                                fontWeight: '600',
                                color: '#124076',
                                letterSpacing: 1,
                                marginTop: '10px'
                            }}>Your Rating: Oily</h4>
                            <p>
                                Your scalp forms small amounts of sebum (scalp oil) to envelop the hair and protect
                                the scalp from
                                external influences.
                            </p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="custom-card h-100 d-flex flex-column">

                            <h1 className={`display-6 text-dark`}><CircleImage src={require('../../../assets/images/scalp-dryness.png')} alt="Scalp Dryness"/> Scalp Dryness</h1>
                            <h4 style={{
                                fontSize: '1.15rem',
                                fontWeight: '600',
                                color: '#124076',
                                letterSpacing: 1,
                                marginTop: '10px'
                            }}>Your Rating: Very Dry</h4>
                            <p>
                                A dry scalp can be caused by eczema, dry weather, water, temperature, and using
                                products containing
                                alcohol. A dry scalp means you aren’t producing enough sebum to keep your scalp
                                moisturized.
                            </p>
                        </Card>
                    </Col>
                </Row>

                <Row className={`my-5 bg-dark p-3 rounded`}>
                    <Col>
                        <p className={`text-white`}> Cosmetrics Care - Improve your hair score with these tips!<span>
                        <Badge className={`mx-2`}>Environmental</Badge> {' '}
                            <Badge className={`mx-2`}>Diet & Nutrition</Badge> {' '}
                            <Badge className={`mx-2`}>Health & Fitness</Badge>{' '}
                            <Badge className={`mx-2`}>Hair Condition</Badge>
                        </span>
                        </p>
                    </Col>
                </Row>
                <Button className="shadow-lg bg-warning fw-medium text-dark border-o">Add your hair care
                    products</Button>
            </Container>
        </Dashboard>
    );
};

export default Home;
