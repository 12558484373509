// src/components/Camera.tsx

import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4} from 'uuid';
// import Button from "../../../../components/Button";
import {Slide, toast, ToastContainer} from 'react-toastify'
import {Camera, Restart, Send, SubmitDocument} from "iconoir-react";
// import {randomUUID} from "node:crypto";

import {Row, Col, Button} from 'react-bootstrap';

interface CapturedImage {
    id: string;
    dataUrl: string;
}

const LiveCamera: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const photoRef = useRef<HTMLCanvasElement | null>(null);
    const [isCameraOn, setIsCameraOn] = useState<boolean>(false);
    const [capturedImages, setCapturedImages] = useState<CapturedImage[]>([]);
    const [capturedList, setCapturedList] = useState<CapturedImage[]>([]);
    const [sessionId, setSessionId] = useState<string>('');
    const maxShots = 3;

    useEffect(() => {
        if (isCameraOn) {
            startCamera();
        } else {
            stopCamera();
        }

        // Clean up function to stop camera on component unmount
        return () => stopCamera();
    }, [isCameraOn]);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                await videoRef.current.play();
            }
            // Generate a new session ID when the camera starts
            setSessionId(uuidv4());
        } catch (error) {
            console.error("Error accessing camera: ", error);
        }
    };

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            (videoRef.current.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    const capturePhoto = () => {
        if (!photoRef.current || !videoRef.current) return;

        const context = photoRef.current.getContext('2d');
        if (!context) return;

        photoRef.current.width = videoRef.current.videoWidth;
        photoRef.current.height = videoRef.current.videoHeight;

        context.drawImage(videoRef.current, 0, 0, photoRef.current.width, photoRef.current.height);
        const imageDataUrl = photoRef.current.toDataURL('image/png');

        const imageId = `${sessionId}_${capturedImages.length + 1}`;

        setCapturedImages(prevImages => {
            if (prevImages.length < maxShots) {
                return [...prevImages, { id: imageId, dataUrl: imageDataUrl }];
            }
            return prevImages;
        });
    };

    const deletePhoto = (index: number) => {
        setCapturedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const resetSession = () => {
        setCapturedImages([]);
        setCapturedList([])
        stopCamera()
    };

    const submitPhotos = () => {
        // Handle the submission logic here, such as sending to a server
        setCapturedList(capturedImages)
        console.log("Submitted Photos: ", capturedImages);
        toast(`Photos submitted successfully! - ${capturedList}`);
        stopCamera()
        resetSession();
    };

    return (
        <Row>
            <div>
                {isCameraOn && (
                    <video ref={videoRef} style={{ width: 'vw-100', maxHeight: 'h-100', borderRadius: '2rem' }} className={`border border-2 border-secondary bg-secondary`} autoPlay muted playsInline />
                )}
                <canvas ref={photoRef} style={{ display: 'none' }} />
            </div>
            <div style={{ marginTop: '10px' }}>
                {!isCameraOn && (
                    <Button variant={`dark`} size={`lg`} className="fw-bold" onClick={() => setIsCameraOn(true)}><Camera />{' '} Take Pictures</Button>
                )}
                {isCameraOn && capturedImages.length < maxShots && (
                    <Button
                        variant={`dark`}
                        size={`lg`}
                        className={`fw-bold fs-6`}
                        onClick={capturePhoto}>
                        capture photo {capturedImages.length + 1} of {maxShots}
                    </Button>
                )}
            </div>
            {capturedImages.length > 0 && (
                <div>
                    <hr />
                    <h3 className={`fw-bold fs-3 opacity-25 my-2`}>captured images</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                        {capturedImages.map((image, index) => (
                            <Col key={index} style={{ position: 'relative' }}>
                                <img src={image.dataUrl} alt={`Captured ${index + 1}`} className={`rounded-3 shadow-sm`} style={{ width: '100%', maxWidth: '150px'}} />
                                {/*<img src={image.dataUrl} alt={`Captured ${index + 1}`} style={{ width: '100%', maxWidth: '150px', margin: '10px 0' }} />*/}
                                <Button  variant={`danger`} size={`sm`} className="fw-bold" onClick={() => deletePhoto(index)} style={{ position: 'absolute', top: 0, right: 0 }}>
                                    Delete
                                </Button>
                                {/*<div style={{ textAlign: 'center', marginTop: '5px', fontSize: '0.65rem' , color: 'lightslategray'}}>{image.id}</div>*/}
                            </Col>
                        ))}
                    </div>
                </div>
            )}
            {capturedImages.length === maxShots && (
                <Row className={`w-100 justify-content-center align-items-center p-3`}>

                   <Col>

                    <Button
                        variant={`dark`} size={`lg`}
                        className="fs-6 fw-bold"
                        onClick={submitPhotos}>
                        Submit Photos
                    </Button>
                   </Col>
                    <Col>

                    <Button
                        variant={`outline-danger`}
                        size={`lg`}
                        className="fs-6 fw-bold"
                        onClick={resetSession}>
                        <Restart/> Reset Session
                    </Button>
                    </Col>
                </Row>

            )}
            {/*<ToastContainer*/}
            {/*    position="bottom-left"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="light"*/}
            {/*    transition={Slide}*/}
            {/*/>*/}
        </Row>
    );
};

export default LiveCamera;
