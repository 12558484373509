import styled from "styled-components";

const Button = styled.button`
    height: 42px;
    padding: 0 1em 0 1em;
    border-radius: 50px;
    outline: 1px solid #303445;
    border: none;
    font-size: 0.875em;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 10px;
`

export default Button;