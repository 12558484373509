import styled from "styled-components";
import GridRow from "../../../../components/GridRow";
import { QuizPageRoot } from "../../style";
import Button from "../../../../components/Button";
import BlurOverlay from "./BlurOverlay";
import ProgressBar from "../../../../components/ProgressBar";
import Row, { RowSpaced } from "../../../../components/Row";
import Column from "../../../../components/Column";
import { useCallback, useMemo, useState } from "react";


const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2.5%;

    button {
        width: 200px;
        align-self: flex-end;
        vertical-align: bottom;
        background-color: #78D3F7;
    }
`;

const ScoreBox = styled(GridBox)`
    flex: 1.4;
    background-color: #E7F7FB;
    border-radius: 24px;
    padding: 2.5% 2.5% 7% 2.5%;
    position: relative;
    min-height: 25vw;
    justify-content: space-between;
    box-sizing: border-box;

    h1 {
        color: #303445;
        margin: 0;
        padding: 0;
        font-size: 1.8em;
        font-weight: 600;
    }

    span {
        width: 100px;
    }
`;

const FlexEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;

    button {
        width: auto;
    }
`;

const RecommendationBox = styled.div`
    position: relative;

    h1 {
        margin: 70px 0 40px 0;
        padding: 0;
    }
`;

const Results: React.FC<QuizPageProps> = ({
    answers
}) => {

    const [products] = useState([
        {
            image: require('../../../../assets/images/product-1.png'),
        },
        {
            image: require('../../../../assets/images/product-2.png'),
        },
        {
            image: require('../../../../assets/images/product-3.png'),
        },
        {
            image: require('../../../../assets/images/product-4.png'),
        },
    ]);

    const results = useMemo(() => {
        return {
            dryness: 85,
            damage: 5,
            oil: 55,
            scalp: 75,
            flakes: 30,
        };
    }, []);

    const handleSignup = useCallback(() => {
        window.location.href = '/signup';
    }, []);

    return (
        <QuizPageRoot>
            <GridRow>
                <ScoreBox>
                    <h1>Hair Condition Scores</h1>


                    <Column style={{ gap: 12 }}>
                        <Row>
                            <span>Dryness</span>
                            <ProgressBar value={results.dryness} />
                        </Row>
                        <Row>
                            <span>Damage</span>
                            <ProgressBar value={results.damage} />
                        </Row>
                        <Column style={{ position: 'relative', gap: 12 }}>
                            <BlurOverlay />
                            <Row>
                                <span>Sebum Oil</span>
                                <ProgressBar value={results.oil} />
                            </Row>
                            <Row>
                                <span>Dry Scalp</span>
                                <ProgressBar value={results.scalp} />
                            </Row>
                            <Row>
                                <span>Flakes</span>
                                <ProgressBar value={results.flakes} />
                            </Row>
                        </Column>
                    </Column>
                </ScoreBox>
                <GridBox>
                    <p>Our smart system uses your data to calculate a score for each area mentioned.</p>
                    <p>These scores provide an indication of your hair condition.</p>
                    <p>Each score highlights how well or not so well your hair and scalp is doing at the moment.</p>
                    <p>The analysis helps the system identify the best products to match your current needs.</p>

                    <FlexEnd>
                        <Button onClick={handleSignup}>{'Sign Up for Full Report'}</Button>
                    </FlexEnd>
                </GridBox>
            </GridRow>

            <RecommendationBox>
                <h1>Product Recommendations for Dryness</h1>

                <RowSpaced style={{ position: 'relative' }}>
                    <BlurOverlay buttonLabel="Sign Up for Recommendations" onButtonPress={handleSignup} />
                    {
                        products && products.map((item, index) => {
                            return (
                                <img style={{ height: 300, width: 240, resize: 'vertical' }} src={item.image} alt="" />
                            );
                        })
                    }
                </RowSpaced>

            </RecommendationBox>
        </QuizPageRoot>
    );
};

export default Results;