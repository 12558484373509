import React from 'react';
import { Facebook, Youtube, Twitter, Linkedin } from 'iconoir-react';
import { Link } from 'react-router-dom';

const social_links = [
    {
        link: 'http://facebook.com/cosmetrics',
        target: '_blank',
        icon: 'Facebook',
        name: 'Facebook'
    },
    {
        link: 'http://twitter.com/cosmetrics',
        target: '_blank',
        icon: 'Twitter',
        name: 'Twitter'
    },
    {
        link: 'https://www.linkedin.com/cosmetrics',
        target: '_blank',
        icon: 'Linkedin',
        name: 'Linkedin'
    },
    {
        link: 'https://www.youtube.com/cosmetrics',
        target: '_blank',
        icon: 'YouTube',
        name: 'Youtube'
    },
    {
        link: 'mailto:cosmetrics@gmailcom',
        target: '_blank',
        icon: 'Email',
        name: 'Contact Us'
    }
];

const SocialLinks = () => {
    return (
        <>
            <ul className={`nav flex-row`}>

                {social_links.map((l, i) => (
                    <li className={`nav-item nav-link ms-1 text-dark fs-5 fw-semibold`}>
                        <Link key={i} to={l.link} target={l.target} className={`text-decoration-none text-secondary text-opacity-50`}>
                            {l.icon}
                        </Link></li>
                ))}
            </ul>
        </>
    );
};

export default SocialLinks;

export function HeaderSocial() {
    return (
        <>
            {social_links.slice(0, 3).map((l, i) => (
                <li key={i}>
                    <a href={l.link} target={l.target}>
                        <i className={l.icon}></i>
                    </a>
                </li>
            ))}
        </>
    );
}

export function SocialShare() {
    return (
        <>
            {social_links.slice(0, 3).map((l, i) => (
                <a key={i} href={l.link} target={l.target}>
                    <i className={l.icon}></i>
                </a>
            ))}
        </>
    );
}
