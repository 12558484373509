import styled from "styled-components";
import Dashboard from "../../../routes/Dashboard";
import {ShoppingBag, Star} from "iconoir-react";
import colors from "../../../style/colors";
import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {Button, Card, CardGroup, Col, Container, Row} from 'react-bootstrap';
import RecommendedProducts from "../../../components/Api/GetRecommendations";
import axios from "axios";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 3vw 5vw 5vw 5vw;
    color: #303445;
    min-height: 100%;
    width: 85%;
    background-color: #FCFCFC;
    gap: 3vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 0.875em;
        margin: 0;
        padding: 0;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 8px;
        width: 200px;
    }
`;

const Recommendations = () => {
    const [userId, setUserId] = useState(null);
    const [productIds, setProductIds] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); // Initial state should be true to show loading initially
    const navigate = useNavigate();

    useEffect(() => {
        const baseURL = process.env.REACT_APP_BACKEND_API

        // Check if the user is logged in (can use localStorage or context here)
        const auth = localStorage.getItem('auth'); // Example: retrieving auth token from localStorage
        if (auth) {
            setUserId(JSON.parse(auth).user.id);
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            setLoading(false);
            navigate('/login');
            return; // Exit useEffect if not logged in to avoid further API calls
        }

        // Fetch recommended product IDs only if user is logged in and has a userId
        async function fetchIds() {
            try {
                if (userId) {
                    setLoading(true); // Set loading to true before fetching data
                    const response = await axios.get(`${baseURL}/users/profiles/recommendations/${userId}`);
                    setProductIds(response.data);
                }
            } catch (error) {
                console.error("Error fetching product IDs:", error);
            } finally {
                setLoading(false); // Set loading to false after fetch completes
            }
        }

        fetchIds().then(p => p);

    }, [navigate, userId]);

    if (loading) {
        return <div>Loading... Recomms Index</div>;
    }

    if (!isLoggedIn) {
        // Redirect to login page or display a login message
        return <div>Please log in to see your recommendations. If you are logged in already, then you need to complete
            the quiz.</div>;
    }

    return (
        <Dashboard>
            <Container>
                <Row className={`my-5`}>
                    <Col>
                        <Row className={`justify-content-center`}>
                            <h1 className={`display-4 text-dark fw-bolder`}>
                                Your recommended products
                            </h1>
                            <p style={{fontSize: '1.35rem'}}>
                                These products have been recommended based on your hair diagnostic, learn more.
                            </p>
                        </Row>
                        <Row>

                            {productIds && productIds.length > 0 && (
                                <Button variant="primary" size="lg" className={`w-25 justify-content-right fw-medium`}>
                                    <ShoppingBag/> Purchase all
                                </Button>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {productIds.length > 0 ? (
                        <RecommendedProducts productIds={productIds}/>
                    ) : (
                        <div className={`my-3`}>
                            <h2 className="h-5 text-secondary fw-medium">
                                No recommendations found. Complete the questionnaire first.
                            </h2>
                            <Link to={"/quiz"}>
                                <Button variant="primary" size="lg">Start Quiz</Button>
                            </Link>
                        </div>
                    )}
                </Row>
                <Row>
                    <CardGroup className="g-4 p-3 my-5">
                        <Card style={{backgroundColor: colors.LIGHTGRAY.pastel, gap: 24, padding: '1rem', border: 0}}>
                            <Col>
                                <h1 style={{fontSize: "1.5rem"}}>Why these products?</h1>
                                <p className={`lead fw-normal`}>
                                    The products chosen have been analysed and found to contain the best formulations to
                                    meet
                                    your stated hair goal and/or address any issues you/we have identified.
                                </p>
                            </Col>
                            <Col>
                                <h1 style={{fontSize: "1.5rem"}}>Expertise & Science</h1>
                                <p className={`lead fw-normal`}>
                                    Our expert-led diagnostic evaluation process reveals your recommended products have
                                    been
                                    assessed to complement your Hair ID profile.
                                </p>
                            </Col>
                            <Col>
                                <h1 style={{fontSize: "1.5rem"}}>Checking for changes</h1>
                                <p className={`lead fw-normal`}>
                                    Change of season, living in a new location, hormonal changes, stress, diet, etc.
                                    affect your
                                    hair! We recommend scheduling 'Change check-in' updates every 2-3 months as new
                                    changes may
                                    warrant updated recommendations.
                                </p>
                            </Col>
                            <span style={{fontSize: "0.95rem", fontStyle: "italic", fontWeight: "500"}}>
                                Disclaimer: Cosmetrics Ai is not affiliated with any cosmetics brand or company and does not receive any form of endorsement to recommend hair care products. We are proudly Independent & Impartial forever!
                            </span>
                        </Card>


                    </CardGroup>
                    <Button
                        variant={`primary`}
                        size={`lg`}
                        style={{margin: "7px"}}
                        className={`w-25`}
                    >
                        <Star/> Rate Recommendation
                    </Button>
                </Row>
            </Container>
        </Dashboard>
    );
};

export default Recommendations;
