// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './screens/Home';
import DashboardHome from './screens/Dashboard/Home';
import Quiz from './screens/Quiz';
import Signup from './screens/Signup';
import Login from './screens/Login';
import NotFound from './screens/NotFound';
import Recommendations from './screens/Dashboard/Recommendations';
import ReportHistory from './screens/Dashboard/ReportHistory';
import Account from './screens/Dashboard/Account';
import Logout from './screens/Logout';
import Index from './screens/ForgotPassword';
import './App.css';
import { useUserActions } from "./hooks/user.actions";
import EmailCapture from "./screens/EmailCapture";
import Soon from "./screens/EmailCapture/soon";
import NavBar from "./routes/Dashboard/NavBar";
import ForgotPassword from "./screens/ForgotPassword";
import About from "./screens/About";
import How from "./screens/How";
import Offers from "./screens/Offers";

const App: React.FC = () => {
    const navigate = useNavigate();  // Use navigate hook inside the component
    const { isLoggedIn, logout } = useUserActions(navigate); // Pass navigate to useUserActions
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        if (isLoggedIn()) {
            setIsUserLoggedIn(true);
        }
    }, [isLoggedIn]);

    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/how" element={<How />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/logout" element={isUserLoggedIn ? <Logout onLogout={logout} /> : <Navigate to="/login" />} />
            <Route path="/dashboard" element={isUserLoggedIn ? <DashboardHome /> : <Navigate to="/login" />} />
            <Route path="/dashboard/recommendations"
                element={isUserLoggedIn ? <Recommendations /> : <Navigate to="/login" />} />
            <Route path="/dashboard/reporthistory"
                element={isUserLoggedIn ? <ReportHistory /> : <Navigate to="/login" />} />
            <Route path="/dashboard/account" element={isUserLoggedIn ? <Account /> : <Navigate to="/login" />} />
            <Route path="/Trynew" element={<Offers />} />
            <Route path="/Stunning" element={<Offers />} />
            <Route path="/scorecard" element={<Offers />} />
            <Route path="/Holistic" element={<Offers />} />
            <Route path="/Book-Trial" element={<Offers />} />
            <Route path="/free-quiz" element={<Offers />} />
            <Route path="/bookoffer" element={<Offers />} />
            <Route path="/soon" element={<Offers />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

const MainApp: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;
