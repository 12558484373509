import React from "react";
// import Button from "../../../../components/Button";
import { QuizButtonFooter, QuizPageRoot } from "../../style";
import Question from "../General/Question";
import { Row, Col, Button, Container } from 'react-bootstrap';
import QuizNav from "../../../../components/QuizNavigation";

const MedicalConditions: React.FC<QuizPageProps> = ({
    answers,
    setAnswer,
    questions,
    onAction,
}) => {
    return (
        <QuizPageRoot>

            {questions && questions.map((question, index) => {
                return (
                    <Question
                        {...question}
                        key={question.id}
                        value={answers[question.id]}
                        setAnswer={setAnswer}
                        index={index}
                    />
                );
            })}

            <QuizNav onAction={onAction} answers={answers} setAnswer={setAnswer} />
        </QuizPageRoot>
    );
};

export default MedicalConditions;