import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Column from "../../components/Column";
import Button from "../../components/Button";

const Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    color: #303445;

    h1 {
        margin: 0 0 50px 0;
        padding: 0;
        text-align: center;
    }

    button {
        width: 150px;
        background-color: #78D3F7;
        align-self: center;
        margin: 50px 0 20px 0;
    }
`;

const StyledButton = styled(Button)`
    font-size: 1.25rem;
    font-weight: 600;
    color: lightpink;
`;

interface LogoutProps {
    onLogout: () => void;
}

const Logout: React.FC<LogoutProps> = ({ onLogout }) => {
    const navigate = useNavigate();

    // Handle logout action
    const handleLogout = () => {
        // Perform the logout action (e.g., clearing tokens)
        onLogout();

        // Redirect the user after successful logout
        navigate('/');
    };

    return (
        <Root>
            <Column>
                <StyledButton onClick={handleLogout}>
                    Logout
                </StyledButton>
            </Column>
        </Root>
    );
};

export default Logout;
