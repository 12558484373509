import styled from "styled-components";

const FlexEnd = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
`

export default FlexEnd;