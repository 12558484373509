import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Layout from "../../components/Layout";
import HairCareProcessFlow from '../../components/Process/processflow';

const How = () => {
    return (
        <Layout>
            <Container>
                <Row>
                    <HairCareProcessFlow />
                </Row>
            </Container>
        </Layout>
    );
};

export default How;
