import styled from "styled-components";

const Root = styled.select`
    display: flex;
    position: relative;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: 1.3px solid #303445;
    border-radius: 8px;
    margin: 8px 0 24px 0;
    padding: 0 12px 0 12px;
`

const Option = styled.option`

`

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    options: string[];
}

const DropDownMenu: React.FC<Props> = ({
    options,
    ...props
}) => {
    return (
        <Root {...props}>
            {options.map((option, index) => (
                <Option key={index} value={option}>
                    {option}
                </Option>
            ))}
        </Root>
    )
}

export default DropDownMenu;