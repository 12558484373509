import styled from "styled-components";
import { QuestionDiv } from "./Question";
import Row from "../../../../components/Row";
import Column from "../../../../components/Column";
import { useMemo } from "react";

const ImageQuestionDiv = styled(QuestionDiv)`
    img {
        height: 180px;
        width: 180px;
    }

    span {
        margin: 12px 0 0 0;
    }
`;

const ImageQuestion: React.FC<QuestionProps & { index: number; }> = ({
    title,
    subheader,
    options,
    index,
    setAnswer,
    value,
    id
}) => {

    const backgroundColor = useMemo(() => {
        const val = index;

        if (val % 2 === 0 || isNaN(val)) {
            return '#FFFFFF';
        } else {
            return '#F9f9f9';
        }
    }, [index]);

    return (
        <ImageQuestionDiv style={{ backgroundColor }} className={`my-3 shadow-sm p-5 rounded-4`} >
            <h3>{title}</h3>
            {subheader && <h5>{subheader}</h5>}

            <Row style={{ marginTop: 30, gap: 30 }}>
                {
                    options.map((val, key) => {
                        const selected = val.label === value;
                        return (
                            <Column style={{ alignItems: 'center' }} onClick={() => setAnswer(id, val.label)}>
                                {val.image && <img src={val.image} alt="" style={{ outline: `${selected ? 2.5 : 0}px solid #303445` }} />}
                                <span>{val.label}</span>
                            </Column>
                        );
                    })
                }
            </Row>
        </ImageQuestionDiv>
    );
};

export default ImageQuestion;