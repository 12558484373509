import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin: 8px 0 24px 0;
    padding: 0 12px 0 12px;
    outline: 1.3px solid #303445;
    box-sizing: border-box;
    height: 52px;
    width: 100%;

    font-size: 0.95em;
`;

const Root = styled.input`
    display: flex;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
`;

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    Icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref"> & React.RefAttributes<SVGSVGElement>>;
}

const TextBox: React.FC<Props> = ({
    Icon,
    ...props
}) => {
    return (
        <Container>
            <Root {...props} />
            {Icon && <Icon />}
        </Container>
    );
};

export default TextBox;