const RED = {
    primary: '#F5004F',
}

const GREEN = {
    primary: '#387F39',
}

const BLUE = {
    primary: '#78D3F7',
    pastel: '#E7F7FB',
}

const PINK = {
    primary: '#',
    pastel: '#FAF0FB',
}

const ORANGE = {
    primary: '#',
    pastel: '#FBE6D8',
}

const PURPLE = {
    primary: '#',
    pastel: '#DFE1FB',
}

const LIGHTGRAY = {
    primary: '#',
    pastel: '#EEEDEB',
    // pastel: '#F5F7F8',
}

const colors = {
    RED,
    GREEN,
    BLUE,
    PINK,
    ORANGE,
    PURPLE,
    LIGHTGRAY,
}

export default colors;