import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define the type for the reply data
interface Reply {
    id: number;
    text: string;
    question: number;
    answer: number;
    user: number;
}

// Define the props for the Reply component
interface ReplyProps {
    userId: number;
    questionId: number;
}

const baseURL = process.env.REACT_APP_BACKEND_API;

const GetUserReply: React.FC<ReplyProps> = ({ userId, questionId }) => {
    const [reply, setReply] = useState<Reply | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchReplyData = async () => {
            try {
                const response = await axios.get<Reply>(`${baseURL}/quiz/replies/${userId}/${questionId}`);
                setReply(response.data);
                console.log("GetReply:",response.data)
            } catch (err: any) {
                setError(err?.message || 'Failed to fetch reply data');
            } finally {
                setLoading(false);
            }
        };

        fetchReplyData();
    }, [userId, questionId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!reply) {
        return <div>No data available</div>;
    }

    // Render the reply data
    return (
        <div>
            <p className="fs-3 fw-medium text-white">{reply.text}</p>
        </div>
    );
};

export default GetUserReply;
