import styled from 'styled-components';
import NavBar from './NavBar';
import {Row} from "react-bootstrap";

const Root = styled.div`
    display: flex;
    //flex-direction: row; /* Arrange items horizontally */
    min-height: 100vh; /* Ensure it takes the full viewport height */
    width: 100vw; /* Full viewport width */
    box-sizing: border-box;
    background-color: #FCFCFC;
`;

const ContentWrapper = styled.div`
    flex-grow: 1; /* Take up the remaining space after the NavBar */
    margin-left: 14rem; /* Offset by the width of the NavBar */
    padding: 1rem;
    overflow: auto; /* Allow content to scroll if it exceeds the viewport height */
    border-radius: 25px;
`;

const Dashboard = ({ children }: any) => {
    return (
        <Row className='bg-light h-100 d-flex flex-column'>
            <NavBar />
            <ContentWrapper>
                {children}
            </ContentWrapper>
        </Row>
    );
}

export default Dashboard;
