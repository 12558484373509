import React from "react";
import { Container, Navbar, Button } from "react-bootstrap";
import styled from "styled-components";
import CosmetricsLogo from "../../assets/images/cosmetrics-logo.svg"; // Update with the correct path

// Styled Components
const StickyHeader = styled(Navbar)`
  position: sticky;
  top: 0;
  z-index: 1050; // Ensure it's above other elements
  background-color: #fff; // Ensure the header has a background color to avoid transparency issues
  padding: 0.5rem 1rem;

  img {
    height: 40px; // Adjust logo size for better mobile display
    @media (min-width: 768px) {
      height: 50px; // Adjust for larger screens
    }
  }
`;

const QuizHeader: React.FC = () => {
  return (
    <StickyHeader expand="md">
      <Container className="d-flex justify-content-between align-items-center shadow-sm rounded-3 p-2">
        <Navbar.Brand href="/">
          <img src={CosmetricsLogo} alt="Cosmetrics Logo" />
        </Navbar.Brand>
        <Button
          variant="danger"
          size="lg"
          className="fw-bold rounded-3"
          onClick={() => { window.location.href = '/'; }}
        >
          Exit Quiz
        </Button>
      </Container>
    </StickyHeader>
  );
};

export default QuizHeader;
