import React from 'react';
import styled from 'styled-components';
import Question from './Question';
import ImageQuestion from './ImageQuestion';
import { Col, Container, Row } from "react-bootstrap";
import QuizNav from "../../../../components/QuizNavigation";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding: 5vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 5% 25% 5% 25%;

    button {
        width: 200px;
    }
`;

const General: React.FC<QuizPageProps> = ({ questions, answers, setAnswer, onAction }) => {
    return (
        <Container className={`position-relative justify-content-center mt-5`}>
            <Row className={`d-flex justify-content-center vh-90`}>
                <Col className={`justify-content-center`}>

                    {questions && questions.map((question, index) => {
                        if (question.type === 'question') {
                            return (
                                <>
                                    <Question
                                        {...question}
                                        key={question.id}
                                        value={answers[question.id]}
                                        setAnswer={setAnswer}
                                        index={index}
                                    />
                                </>

                            );
                        } else {
                            return (
                                <>
                                    <ImageQuestion
                                        {...question}
                                        key={question.id}
                                        value={answers[question.id]}
                                        setAnswer={setAnswer}
                                        index={index}
                                    />
                                </>
                            );
                        }
                    })}

                    <QuizNav onAction={onAction} answers={answers} setAnswer={setAnswer} />

                </Col>
            </Row>
        </Container>
    );
};

export default General;
