import PhotoStandards from "../../../../components/PhotoStandards";
import { Upload, Restart, Forward, ArrowLeft, ArrowRight } from "iconoir-react";
import LiveCamera from "../LiveCamera";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Component } from "react";
import QuizNav from "../../../../components/QuizNavigation";

class UploadPhotos extends Component<QuizPageProps> {
    render() {
        let {
            answers,
            setAnswer,
            onAction,
        } = this.props;
        return (
            // <Row className={`w-100`}>
            <>
                <Row className={`d-flex justify-content-evenly center mt-5 p-3 h-100`} style={{ backgroundColor: "" }}>
                    <Col md={5}
                        className={`d-flex flex-column rounded-3 shadow-sm`}>
                        <PhotoStandards />
                        <Row className={`p-3 bg-black mt-3 rounded-3 `}>
                            <Col className={`lead`}>

                                <ol style={{ lineHeight: "2.75rem", color: "#FFf" }}>
                                    <li>Take pictures in good light</li>
                                    <li>Hair should not be wet</li>
                                    <li>
                                        Pictures should be clear and visible
                                    </li>
                                    <li>
                                        Submit 3 pictures (Top, back, and side)
                                    </li>
                                    <li>
                                        When you have finished taking 3 pictures and you submit, the quiz will continue
                                    </li>
                                </ol>
                                <small className={`text-warning`}>
                                    Disclaimer - by sharing your image you are granting permission for us to use your
                                    image
                                    for internal use for technology development.
                                </small>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className={`bg-white border border-2 rounded-3 mx-2 my-2 p-3 v-100 shadow-sm`}>
                        <Row className="justify-content-center align-items-center">
                            <Col className="text-center">
                                <Button variant="warning" size="lg" className="fw-bold my-2">
                                    <Upload /> Upload Pictures
                                </Button>

                                <h3 className="text-center my-3 text-secondary fs-1 text-opacity-25 fw-bolder">
                                    or
                                </h3>

                                <LiveCamera />
                            </Col>
                        </Row>

                    </Col>

                </Row>
                <QuizNav onAction={onAction} answers={answers} setAnswer={setAnswer} />
            </>
        );
    }
}

export default UploadPhotos;