import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import FlexEnd from "../../components/FlexEnd";
import CosmetricsLogo from '../../assets/images/cosmetrics-logo-white.svg';
import {HomeSimple, LogIn, LogOut, QuoteMessage, Reports, Star, SubmitDocument, User} from 'iconoir-react';
import {useUserActions} from "../../hooks/user.actions";
import {Link, useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";

const theme = {
    primaryColor: "#180161",
    secondaryColor: "#4a90e2",
    textColor: "#FFFFFF",
};

const NavBarRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 14rem;
    box-sizing: border-box;
    background-color: ${theme.primaryColor};
    padding: 1vw 0.5vw;
    color: ${theme.textColor};
    position: fixed;
    left: 0;
    top: 0;

    img {
        align-self: flex-start;
        margin-bottom: 4vw;
        max-width: 80%;
        height: auto;
    }
`;

const ButtonBase = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    width: 100%;
    outline: none;
    border: none;
    gap: 8px;
    background-color: transparent;
    color: ${theme.textColor};
    font-size: 0.875em;
    letter-spacing: 0.5px;
    cursor: pointer;
`;

const IconStyle: React.CSSProperties = {
    fontSize: "1.15rem",
    borderRadius: "150px",
    backgroundColor: "black",
    padding: "5px",
};

// const LinkStyled = styled.a`
//     background-color: ${theme.secondaryColor};
//     color: white;
//     padding: 10px 20px;
//     border-radius: 5px;
//     text-decoration: none;
//     display: inline-block;
//
//     &:hover {
//         background-color: #357abd;
//     }
// `;

type NavButtonProps = {
    label: string;
    IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    onClick: () => void;
};

const NavButton: React.FC<NavButtonProps> = ({label, IconComponent, onClick}) => (
    <ButtonBase onClick={onClick}>
        <IconComponent style={IconStyle}/> {/* Render the IconComponent */}
        {label}
    </ButtonBase>
);

const NavBar: React.FC = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const {isLoggedIn, logout} = useUserActions(navigate);

    useEffect(() => {
        if (isLoggedIn()) {

        }
        const authData = localStorage.getItem('auth') || "";
        setIsUserLoggedIn(!!authData);
    }, [isLoggedIn]);

    const handleNavigate = useCallback((path: string) => navigate(path), [navigate]);

    return (
        <NavBarRoot>
            <img className="p-2" src={CosmetricsLogo} alt="Cosmetrics AI"/>

            <Col>
                <NavButton label="Hair Analysis" IconComponent={Reports} onClick={() => handleNavigate('/dashboard/')}/>
                <NavButton label="Recommendations" IconComponent={Star}
                           onClick={() => handleNavigate('/dashboard/recommendations')}/>
                <NavButton label="Report History" IconComponent={SubmitDocument}
                           onClick={() => handleNavigate('/dashboard/reporthistory')}/>
                <NavButton label="Account" IconComponent={User} onClick={() => handleNavigate('/dashboard/account')}/>
                <NavButton label="Home" IconComponent={HomeSimple} onClick={() => handleNavigate('/')}/>
            </Col>

            <FlexEnd>
                <Col style={{paddingTop: '1vw'}}>
                    <NavButton label="Give Feedback" IconComponent={QuoteMessage}
                               onClick={() => handleNavigate('/feedback')}/>
                    {isUserLoggedIn ? (
                        <NavButton label="Logout" IconComponent={LogOut} onClick={logout}/>
                    ) : (
                        <Link to="/login" className={`text-white fs-6 fw-medium`}>
                            <LogIn style={IconStyle}/> {' '} Login
                        </Link>
                    )}
                </Col>
            </FlexEnd>
        </NavBarRoot>
    );
};

export default NavBar;
