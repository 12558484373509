import {Row, Container} from "react-bootstrap";
import SamplePicture from "../LabeledPicture"; // Assuming Image is in the same directory

const PhotoStandards = () => {
    return (
        // E5E7EB
        <Row className={`d-flex justify-content-center py-2 px-1 mt-2 rounded-3`} style={{backgroundColor: "#f8f8f8"}}>
            <Row className={`mb-2`}>
                <SamplePicture imageSrc={require("../../assets/images/hair-1.png")} imageStatus={"Approved"}/>
                <SamplePicture imageSrc={require("../../assets/images/hair-2.png")} imageStatus={"Approved"}/>
                <SamplePicture imageSrc={require("../../assets/images/hair-3.png")} imageStatus={"Approved"}/>
            </Row>
            <Row>
                <SamplePicture imageSrc={require("../../assets/images/hair-4.png")} imageStatus={"Too Close"}/>
                <SamplePicture imageSrc={require("../../assets/images/hair-5.png")} imageStatus={"Too Dark"}/>
                <SamplePicture imageSrc={require("../../assets/images/hair-6.png")} imageStatus={"Background"}/>
            </Row>
        </Row>
    );
};

export default PhotoStandards;
