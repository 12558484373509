import React from "react";
import {Link} from 'react-router-dom'
import styled from "styled-components";
import {RowSpaced} from "../../../components/Row";
import {Button, Card, Image,Row} from 'react-bootstrap'


const FrequencyBadge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FCDE70;
    //background-color: #E7F7FB;
    font-weight: 600;
    font-size: 1.25rem;
    border-radius: 8px;
    height: 40px;
    padding: 0 12px 0 12px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 200;

    span {
        margin: 0;
        padding: 0;
    }
`;

interface Props extends RecommendItem {
}

const ProductCard: React.FC<Props> = ({
                                          price,
                                          image_url,
                                          name,
                                          ingredients,
                                          link
                                      }) => {

    const IMAGE_PREFIX = `${process.env.REACT_APP_DOMAIN}/static/`;
    const DEFAULT_IMAGE = `${process.env.REACT_APP_DOMAIN}/static/media/default_image_1.jpg`;

    // Use the default image if image_url is not provided
    const imageSrc = image_url ? `${IMAGE_PREFIX}${image_url}` : DEFAULT_IMAGE;

    return (
        // <Card style={{minWidth: "100%", minHeight: '50vh', padding: "1rem", border:0}}>
        <Card className={`p2`} style={{minHeight: '70vh',maxHeight:'70vh', border:0}}>
            <FrequencyBadge>
                <span>£{price}</span>
            </FrequencyBadge>
            <Image
                src={imageSrc}
                alt={name}

                className="img-fluid p-5"
                style={{maxHeight: '40vh', minHeight:'40vh', width: '100%'}}
            />
            <Row style={{alignItems: 'start', padding: '1rem'}}>
                <span style={{fontSize: '1.05rem', fontWeight: '500'}}>{name}</span>
                <span style={{fontSize: '1.45rem', alignItems:'end', fontWeight: '500'}}>£{price}</span>
            </Row>
            <p style={{color: "#000EBA", fontSize: '1.15rem', fontWeight: '500', padding: '10px'}}>More info...</p>
            <Link to={link}>
                <Button className="btn-lg btn-outline-light sticky-bottom fs-5">Purchase from retailer</Button>
            </Link>
        </Card>
    );
};

export default ProductCard;
