import React, {useState} from "react";
import {Container, Form, Row, Button, Alert, Col} from "react-bootstrap"; // Import React-Bootstrap components
import './EmailCapture.css';
import NavBar from "../../routes/Dashboard/NavBar";

const EmailCapture: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validateEmail(email)) {
            console.log("Email submitted:", email);
            // TODO: put to email service
            setSubmitted(true);
            setError("");
            // Send email to your backend service here.
        } else {
            setError("Please enter a valid email address.");
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Row className={`w-100`}>
            <Col className="email-capture-container1 mt-5 mb-5 rounded-5 bg-light">
                <h2 className="email-header1 text-primary fw-bolder fs-4">Sign Up for Updates!</h2>
                {!submitted ? (
                    <Form onSubmit={handleSubmit} className="email-form1">
                        <Form.Group controlId="emailInput">
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                isInvalid={!!error}
                                className={` text-dark border border-2`}
                                required
                            />
                            {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                        </Form.Group>

                        <Button size={`lg`} type="submit" className="submit-button fw-bolder my-3 shadow-sm"
                                variant="primary">
                            notify me
                        </Button>
                    </Form>
                ) : (
                    <Alert variant="success" className="thank-you-message">
                        Thank you for signing up!
                    </Alert>
                )}
            </Col>
        </Row>
    );
};

export default EmailCapture;
