import React from 'react';
import Layout from '../../components/Layout';
import EmailCapture from '../EmailCapture';

const Offers = () => {
    return (
        <Layout>

            <div>
                <EmailCapture />
            </div>
        </Layout>
    );
};

export default Offers;
