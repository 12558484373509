import React from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import CosmetricsLogo from "../../assets/images/cosmetrics-logo.svg"; // Assuming you have a logo asset
import Layout from "../../components/Layout";
import aboutShot from '../../assets/images/hero_5_afro.webp';
import StartQuiz from "../../components/StartQuiz";


const AboutUs: React.FC = () => {
    return (
        <Layout>
            <Container className={`mt-5`}>

                <Row className="d-flex justify-content-center text-center my-5">
                    <Col>
                        <h1 className="display-4 fw-bold">Changing Lives Through Hair Care...</h1>
                        <h2 className="text-muted">One head at a time!</h2>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col md={6} style={{ height: "h-100" }}>
                        <Image
                            src={aboutShot}
                            fluid
                            rounded
                            alt="Cosmetrics AI Technology"
                            className="mb-3 rounded-5"

                        />
                    </Col>
                    <Col md={6}>
                        <h3 className="fw-bold">Our Mission</h3>
                        <p>
                            Founded in London, England, <strong>Cosmetrics AI</strong> is the culmination of passion, vision, persistence, and
                            the burning desire to solve a problem that has the potential to literally change lives.
                        </p>
                        <p>
                            Our mission is to recommend the very best hair products for hair care to consumers without compromise.
                            <strong> Cosmetrics AI</strong> is not affiliated with any cosmetic brand or company and will not accept any form of paid
                            endorsement to recommend products or brands EVER!
                        </p>
                    </Col>
                </Row>

                <Row className="text-center my-5">
                    <Col>
                        <h2 className="fw-bold text-primary display-4 text-opacity-75 text-center">Built with Knowledge & Care</h2>
                        <p className="fs-3 text-dark">Over 100+ people have contributed towards creating our game-changing technology.</p>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col md={4}>
                        <Card className="text-left">
                            <Card.Body>
                                <Card.Title className="fw-bolder fs-3 text-center text-dark text-opacity-100">Ambition</Card.Title>
                                <Card.Text className="fs-4">To be the world leader in cosmetic technology by creating true hair care.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-left">
                            <Card.Body>
                                <Card.Title className="fw-bolder fs-3 text-center text-dark  text-opacity-100">Beauty + Science = Technology</Card.Title>
                                <Card.Text className="fs-4">Things haven't changed for 100 years in hair care! Healthy hair starts with science.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-left">
                            <Card.Body>
                                <Card.Title className="fw-bolder fs-3 text-center text-dark text-opacity-100">Healthy Hair Starts with Science</Card.Title>
                                <Card.Text className="fs-4">
                                    Cosmetrics AI analyzes your hair condition using advanced technology to create tailored hair care
                                    product recommendations.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col>
                        <h3 className="fw-bold text-primary display-4 text-opacity-75 text-center">Our Unique Service</h3>
                        <p className="fs-4">
                            Our cosmetic professionals have spent many years trialing and testing products on clients - we are confident
                            in our ability to match you to the best products in the marketplace that meet your individual needs.
                        </p>
                        <p className={`fs-4`}>
                            We provide a way for those that need better product choices to cut through the noise and find products that work
                            well for them.
                        </p>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col md={6}>
                        <h4 className="fw-bold">The Problem</h4>
                        <p className={`fs-4`}>
                            "One product works for all!" - This is false information from a bygone era. Many are left with cupboards full
                            of failed products, confusing hair care advice, and disappointing results.
                        </p>
                    </Col>
                    <Col md={6}>
                        <h4 className="fw-bold">Our Solution</h4>
                        <p className={`fs-4`}>
                            Sometimes you need a team of ingredients-obsessed trained scientists, engineers, and passionate visionaries to help
                            you with your hair care regimen. That's where we come in!
                        </p>
                    </Col>
                </Row>

                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="fw-bold text-primary">The Future of Hair Care</h2>
                        <p className={`fs-4`}>
                            Take a peek at the future of effective next-generation hair care product selection. Our system uses technology to
                            bring effective high-quality products to the right person at the right time!
                        </p>
                    </Col>
                </Row>

                <Row className="text-center mb-5">
                    <Col>
                        <h4 className="fw-bolder text-primary">Who's Crazy Idea is This?</h4>
                        <p className={`fs-4`}>
                            Our team of excited scientists, engineers, and cosmetic product enthusiasts created this game-changing tool to
                            evaluate your hair and identify the right products for you.
                        </p>
                        <p className={`fs-4`}>
                            We’ve combined decades of consumer feedback, added our creativity, professionalism, and industry-shaking ambition
                            to bring forth an exceptional alternative to finding hair products that actually work!
                        </p>

                    </Col>
                </Row>

            </Container>
        </Layout>
    );
};

export default AboutUs;
