import React, { useState } from 'react';
import { ProgressBar, Card, Button, Row, Container, Col } from 'react-bootstrap';

const HairCareProcessFlow: React.FC = () => {
    const [step, setStep] = useState(1);

    const stages = [
        "Take the Quiz: Complete the questionnaire with information about you and your hair.",
        "Upload or take pictures of your hair - front view, back view, and a side view.",
        "We analyze the pictures and questionnaire using our technology.",
        "We recommend products specific to your hair.",
        "Apply the recommendations and repeat the steps.",
    ];

    const nextStep = () => {
        if (step < stages.length) {
            setStep(step + 1);
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    return (
        <Row className="bg-light rounded-4 p-5 shadow-sm w-100">
            <Col>

                <h1 className="text-center my-4 text-dark text-opacity-57" style={{ fontSize: "2.5rem", fontWeight: "900" }}>Hair Care Process</h1>

                <ProgressBar variant={"primary"} now={(step / stages.length) * 100} label={`${step} / ${stages.length}`} />

                <Card className="my-4">
                    <Card.Body>
                        <Card.Title style={{ fontSize: "1.5rem", fontWeight: "700" }}>Step {step}</Card.Title>
                        <Card.Text className="fs-3 text-dark text-opacity-70" >{stages[step - 1]}</Card.Text>
                    </Card.Body>
                </Card>

                <div className="d-flex justify-content-between">
                    <Button onClick={prevStep} disabled={step === 1}>
                        Previous
                    </Button>
                    <Button onClick={nextStep} disabled={step === stages.length}>
                        Next
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default HairCareProcessFlow;
