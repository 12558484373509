import { Col, Image, Row } from 'react-bootstrap';
// internal
import bg from '../../assets/images/hero_5_afro.webp';
import slider_img from '../../assets/images/hero_5_afro_bw_1.webp';
import StartQuiz from "../StartQuiz";
import ProcessCarousel from "../Process/ProcessCarousel";

const LandingHeroBanner = () => {
    const preferredColor = "#DCF2F1"; //DCF2F1 94FFD8 F9E400  FFF455 77E4C8 06D001 FFE1FF
    return (
        <Row className={`d-flex justify-content-center p-0 rounded-5 position-relative my-5 shadow-sm`}>
            <Row
                className={`d-flex justify-contents-center align-items-center w-100  p-0 rounded-5 `}
                style={{
                    backgroundColor: `${preferredColor}`,
                }}>

                <Row className='d-flex justify-content-center m-0 p-0' style={{ backgroundImage: `url(${bg})` }}>
                    <Col md={5} lg={6} sm={12} xs={12} className='p-0' style={{ zIndex: 1000 }}>
                        <h3 className='text-dark text-opacity-25 m-0 z-3 p-5'
                            style={{ fontSize: '8rem', fontWeight: '900', overflow: 'visible' }}
                        >CosmetricsAI</h3>
                        <div className='p-5'>

                            <h1 className='text-dark text-opacity-100'
                                style={{ fontSize: '4rem', fontWeight: '900', lineHeight: '3.5rem' }}>
                                Your hair needs<br />
                                the right touch
                            </h1>
                            <h3 className={`fs-4 w-75 text-dark fst-italic text-opacity-75 fw-bolder my-3 p-2 rounded-4`}>
                                Click 'Start Quiz' to begin process!
                            </h3>
                            <Row className='p-1'>
                                <StartQuiz selectedColor={`dark`} />
                            </Row>
                            <ProcessCarousel />
                        </div>
                    </Col>
                    <Col md={7} lg={6} sm={12}
                        className='me-auto '>
                        <div className='text-end'>
                            <Image
                                src={slider_img}
                                alt='slider image'
                                width={`120%`}
                                height={`auto`}
                                className={`p-0 mt0-5`}
                                style={{ overflow: 'clip', }}
                            />
                        </div>
                    </Col>
                </Row>
            </Row>
        </Row>
    );
};

export default LandingHeroBanner;
