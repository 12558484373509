import styled from "styled-components";
import { QuizPageRoot } from "../../style";

const GradientBox = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 30px 0;
    color: #FFFFFF;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;

    h5 {
        font-weight: 400;
        margin: 12px 0 0 0;
        padding: 0;
        text-align: center;
    }
`;

const AnalysingResults: React.FC<QuizPageProps> = () => {

    return (
        <QuizPageRoot>
            <GradientBox>
                <img src={require('../../../../assets/images/background-gradient.png')} alt="" />

                <h1>Analysing Results</h1>

            </GradientBox>

            <Footer>
                <h5>Cosmetrics AI uses uses our technology and expert knowledge to make hair care simple!</h5>
                <h5>Built with expert knowledge & care - 50+ experts in Hair, Health & Nutrition have contributed towards creating our game changing technology!</h5>
            </Footer>
        </QuizPageRoot>
    );
};

export default AnalysingResults;