import React from "react";
import { Col, Image } from 'react-bootstrap';
import {Check, Xmark} from "iconoir-react";

interface StandardProps {
    imageSrc: string;
    imageStatus: string;
}

const SamplePicture: React.FC<StandardProps> = ({ imageStatus, imageSrc }) => {
    return (
        <>
            <Col className="position-relative rounded-5">
                <Image src={imageSrc} className="display d-flex flex-column mx-0 w-100 rounded-3" />

                {/* Label positioned on top of the image */}
                <h3 className="z-3 position-absolute align-baseline bottom-0 start-20 translate-bottom fw-bold bg-black p-1 rounded w-70"
                    style={{color: '#FFEB00', letterSpacing: '1px', fontSize: '1.125rem', fontWeight: '600', padding: '5px'}}>
                    {imageStatus}
                    {imageStatus === "Approved" ? (
                        <Check color="#8FD14F" height={28} width={28} />
                    ) : (
                        <Xmark color="#FF6600" height={28} width={28} />
                    )}
                </h3>
            </Col>
        </>
    );
}

export default SamplePicture;
