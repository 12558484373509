import React, { useState } from 'react';
import { Button, Card, Form, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Layout from "../../components/Layout";

// Define the type for the response from the backend
interface ForgotPasswordResponse {
    success: boolean;
    message: string;
}

const Index: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Handle form submission
    const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Make a POST request to the backend API to handle the forgot password
            const response = await axios.post<ForgotPasswordResponse>('http://localhost/api/users/forgot-password', {
                email,
            });

            if (response.data.success) {
                setMessage('Password reset link sent! Check your email.');
                setError(null);
            } else {
                setMessage('');
                setError(response.data.message || 'An error occurred.');
            }
        } catch (err: any) {
            console.error('Forgot Password Error:', err);
            setError(err.response?.data?.message || 'An error occurred while requesting a password reset.');
            setMessage('');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <Row fluid className="d-flex justify-content-center align-items-center w-100 me-auto">
                <Col>
                    <Card className="p-4 shadow-lg">
                        <Card.Body>
                            <h2 className="text-center mb-4">Forgot Password</h2>
                            {message && <Alert variant="success">{message}</Alert>}
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleForgotPassword}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
                                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                                </Button>
                                <div className="mt-3 text-center">
                                    Remembered your password? <a href="/login">Login</a>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default Index;
