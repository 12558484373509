import styled from 'styled-components';
import CosmetricsLogo from '../../assets/images/cosmetrics-logo.svg';
import Button from '../../components/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Landing from './pages/Landing';
import General from './pages/General';
import Column from '../../components/Column';
import UploadPhotos from './pages/UploadPhotos';
import ExtraInformation from './pages/ExtraInformation';
import MedicalConditions from './pages/MedicalConditions';
import Lifestyle from './pages/Lifestyle';
import HairCare from './pages/HairCare';
import Preferences from './pages/Preferences';
import AnalysingResults from './pages/AnalysingResults';
import Results from './pages/Results';
// import Row from '../../components/Row';
import colors from '../../style/colors';
import QuizHeader from '../../components/QuizHeader';
import CustomProgressBar from "../../components/CustomProgressBar";
import { Col, Container, Row } from 'react-bootstrap';
import QuizNav from '../../components/QuizNavigation';
//
// const Root = styled.div`
//     display: flex;
//     flex-direction: column;
//     box-sizing: border-box;
//     height: 100vh;
// `;
//
// const Header = styled.header`
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     box-sizing: border-box;
//     height: 80px;
//     width: 100vw;
//     padding-left: calc(80px - 1em);
//     padding-right: calc(80px - 1em);
//     background-color: #FCFCFC;
//     z-index: 10001;
//     position: fixed;
//
//     button {
//         width: 200px;
//     }
// `;

const ProgressHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
    border: 2px solid #f6f6f6;
    background: #FAF7F0;
    padding: 1rem;
    margin: 0 auto;
    //left: 10%;
    bottom: 5%;
    //margin-top: 20px;
    //padding: 3vw 3vw 0 3vw;
    padding: 1rem;
    width: 100%;

    h1 {
        font-size: 2em;
        font-weight: 700;
        color: #C5538F;
        margin: 0;
        padding: 0;
    }

    h5 {
        font-size: 0.9em;
        font-weight: 400;
        //margin: 8px 0 0 0;
        padding: 0;
    }
`;

const ProgressHeaderIndex = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #eeeeee;
    width: 40vw;
    padding-left: .5rem;
    padding-top: 2rem;
    gap: 2px;


`;

const ProgressHeaderTab = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 0 0 12px;
    height: 34px;
    width: 140px;
    border-radius: 10px;

    span {
        font-size: 0.875em;
    }
`;

const ProgressBar = styled.div`
    display: flex;
    height: 30px;
    width: 100%;
    background-color: #303445;
    overflow: hidden;
    margin-bottom: 40px;

    div {
        height: 100%;
        background-color: #FFEB00;
        font-weight: 700;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 0 2px 0 0;
    }

    span {
        font-size: 0.875em;
        font-weight: 500;
        color: #303445;
        align-self: flex-end;
    }
`;

interface Answer {
    [key: string]: string;
}
enum AnswerType {
    Radio,
    Checkbox,
    Boolean
}
const initialQuestions: Question[] = [
    {
        id: '0',
        title: "What's your main hair goal?",
        subheader: 'Select one answer',
        options: [
            { label: 'Growth' },
            { label: 'Moisture Retention' },
            { label: 'Clean hair and scalp' },
            { label: 'Healthy hair' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '1',
        title: "Do you need help with any of the following issues?",
        subheader: 'Select one  answer',
        options: [
            { label: 'Scalp Dryness' },
            { label: 'Split ends / Hair breakage' },
            { label: 'Thinning hair' },
            { label: 'None of the above' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '2',
        title: "How would you describe your hair overall?",
        subheader: 'Select one  answer',
        options: [
            { label: 'Generally dry' },
            { label: 'Oily roots and dry ends' },
            { label: 'Flaky scalp and dry ends' },
            { label: 'Fairly balanced / No outstanding issues' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '3',
        title: "How long is your hair today?",
        subheader: 'Select one  answer',
        options: [
            { label: 'Short: Close crop, Fade' },
            { label: 'Chin Length' },
            { label: 'Shoulder Length' },
            { label: 'Past my shoulders' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '4',
        title: "How long does it take your hair to naturally dry?",
        subheader: 'Select one  answer',
        options: [
            { label: 'Less than 4 hours' },
            { label: 'More than 4 hours' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '5',
        title: "Select your natural hair type when you air dry",
        options: [
            { label: 'Type 3 Curly', image: require('../../assets/images/type-3-curly.png') },
            { label: 'Type 4A', image: require('../../assets/images/type-4a.png') },
            { label: 'Type 4B', image: require('../../assets/images/type-4b.png') },
            { label: 'Type 4C', image: require('../../assets/images/type-4c.png') },
        ],
        type: 'image_question',
        choice: 'radio',
    },
    {
        id: "6",
        title: "Do you currently have split ends?",
        options: [
            {
                label: 'Yes',
            },
            {
                label: 'No',
            },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: '7',
        title: "Select your hair density",
        options: [
            { label: 'Barely any scalp', image: require('../../assets/images/hair-density-1.png') },
            { label: 'Little bit of scalp', image: require('../../assets/images/hair-density-2.png') },
            { label: 'Fair amount of scalp', image: require('../../assets/images/hair-density-3.png') },
        ],
        type: 'image_question',
        choice: 'radio',
    },
    {
        id: '8',
        title: "Select your hair texture",
        subheader: 'Closest to your scalp, how thick is an individual hair strand?',
        options: [
            { label: 'Thin / Fine', image: require('../../assets/images/hair-texture-1.png') },
            { label: 'Medium', image: require('../../assets/images/hair-texture-2.png') },
            { label: 'Thick', image: require('../../assets/images/hair-texture-3.png') },
        ],
        type: 'image_question',
        choice: 'radio',
    },
];

const medicalQuestions: Question[] = [
    {
        id: 'medical_1',
        title: 'Do you have any of the following conditions?',
        options: [
            { label: 'Anaemia' },
            { label: 'Depression' },
            { label: 'Diabetes' },
            { label: 'Hormone imbalance' },
            { label: 'Liver problems' },
            { label: 'Polycystic Ovarian Syndrome (PCOS)' },
            { label: 'Thyroid problem' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'medical_2',
        title: 'Have you had a raised temperature/fever due to illness in the past 3 months?',
        options: [
            { label: 'Yes' },
            { label: 'No' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'medical_3',
        title: 'Do you have any allergies?',
        options: [
            { label: 'Lactose / Dairy' },
            { label: 'Caffeine' },
            { label: 'Nuts' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'medical_4',
        title: 'Have you stopped or started any prescription medication?',
        subheader: 'Not including asthma medication',
        options: [
            { label: 'Yes' },
            { label: 'No' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'medical_5',
        title: 'Do you have an iron deficiency  or any other dietary deficiency?',
        options: [
            { label: 'Yes' },
            { label: 'No' },
        ],
        type: 'question',
        choice: 'radio',
    },
];

const lifestyleQuestions: Question[] = [
    {
        id: 'lifestyle_1',
        title: 'Which one of these describes your diet?',
        subheader: 'Select one  answer',
        options: [
            { label: 'No restrictions' },
            { label: 'Balanced or health concious' },
            { label: 'Vegetarian / Vegan' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_2',
        title: 'Do you take any vitamin or mineral supplements?',
        options: [
            { label: 'Yes' },
            { label: 'No' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_3',
        title: 'How often do you skip meals?',
        options: [
            { label: 'Everyday' },
            { label: 'Less than 3 times per week' },
            { label: 'Currently intermittent fasting' },
            { label: 'I do not skip meals' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_4',
        title: 'Do you usually go more than 5 hours without eating?',
        options: [
            { label: 'Yes' },
            { label: 'No' },
            { label: 'Currently intermittent fasting' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_5',
        title: 'How much water do you drink a day?',
        options: [
            { label: '1/4 Litre -  1 average cup of water' },
            { label: '1/2 Litre' },
            { label: '1+ Litre' },
            { label: 'I do not drink water daily' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_6',
        title: 'Is your general health...',
        subheader: 'Select one  answer',
        options: [
            { label: 'Good' },
            { label: 'Fair' },
            { label: 'Poor' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_7',
        title: 'Is your current fitness level...',
        subheader: 'Select one  answer',
        options: [
            { label: 'Good' },
            { label: 'Fair' },
            { label: 'Poor' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_8',
        title: 'How many times do you workout a month?',
        subheader: 'Select one  answer',
        options: [
            { label: '1-2' },
            { label: '3-5' },
            { label: '6+' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'lifestyle_9',
        title: 'Where do you workout?',
        subheader: 'Select one  answer',
        options: [
            { label: 'Indoors / GYM' },
            { label: 'Outdoors / Bootcamps' },
            { label: 'Chlorine swimming pool' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'lifestyle_10',
        title: 'How often do you experience stress?',
        subheader: 'Select one  answer',
        options: [
            { label: 'Everyday' },
            { label: 'Multiple times a week' },
            { label: 'Rarely' },
        ],
        type: 'question',
        choice: 'radio',
    },
];
const haircareQuestions: Question[] = [
    {
        id: 'haircare_1',
        title: 'How does your hair feel with week?',
        subheader: 'In the last 7-10 days...',
        options: [
            { label: 'Weighed down' },
            { label: 'Coarse and dry' },
            { label: 'Limp' },
            { label: 'Not responding to anything' },
            { label: 'Normal' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_2',
        title: 'How often do you wash your hair?',
        options: [
            { label: 'Once a week' },
            { label: 'More than once a week' },
            { label: 'Twice a month' },
            { label: 'Once a month' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_3',
        title: 'How often do you skip meals?',
        subheader: 'Select one  answer',
        options: [
            { label: 'Everyday' },
            { label: 'Less than 3 times per week' },
            { label: 'Currently intermittent fasting' },
            { label: 'I do not skip meals' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_4',
        title: 'When you wash your hair have you noticed any of the following?',
        subheader: 'Select all that apply',
        options: [
            { label: 'Hair colour fades easily or dulls rapidly' },
            { label: 'Problems with shampoo lathering' },
            { label: 'Hair feels dry, no matter how much it is washed or deep conditioned' },
            { label: 'Chronic breakage that is not fixed with treatment and care' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'haircare_5',
        title: 'How often do you use hair care products, excluding shampoo?',
        subheader: 'E.g. Sprays, creams, oils, butters, leave in conditioners etc',
        options: [
            { label: 'Daily' },
            { label: '3-4 times a week' },
            { label: 'Once a week or less' },
            { label: 'Never' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_6',
        title: 'Have you received any hair enhancers in the last 9 months?',
        options: [
            { label: 'Natural hair extensions' },
            { label: 'Synthetic extensions' },
            { label: 'Weave' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'haircare_7',
        title: 'What is your current fitness level?',
        options: [
            { label: 'Good' },
            { label: 'Fair' },
            { label: 'Poor' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_8',
        title: 'Does your hair retain noticeable odours from food or smoke?',
        options: [
            { label: 'Yes' },
            { label: 'No' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_9',
        title: 'Which option best describes how your hair behaves?',
        subheader: "Select the option that covers most of you hair's natural behaviours.",
        options: [
            { label: 'Repels moisture when wet, prone to build up, oils don’t penetrate quickly, often shiny, resists penetrations of chemicals' },
            { label: 'Bouncy and elastic, not prone to breakage or brittle, requires little maintenance, easily accepts moisture, holds styles well, accepts colour well and evenly' },
            { label: 'Easily absorbs water, requires more hair care products, loses moisture easily, prone to frizz and tangling, dries quickly, takes on colour easily, colour treatments fade easily.' }
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_10',
        title: 'What do you use to style your hair?',
        subheader: 'Select all that apply',
        options: [
            { label: 'Blow dryer' },
            { label: 'Straightening iron' },
            { label: 'Curling iron/wand' },
            { label: 'Gel/pomade/paste' },
            { label: 'Hairspray' },
            { label: 'Mousse/cremes/butters' },
            { label: 'Oils' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'checkbox',
    },
    {
        id: 'haircare_11',
        title: 'How often do you apply heat to your hair?',
        subheader: 'Select one answer',
        options: [
            { label: 'Everyday or every other day' },
            { label: 'Once or twice a week' },
            { label: 'Once or twice a month' },
            { label: 'Rarely' },
            { label: "I don't apply heat" },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_12',
        title: 'Which part of your routine would you like to improve most?',
        subheader: 'Select one answer',
        options: [
            { label: 'Washing' },
            { label: 'Drying' },
            { label: 'Styling' },
            { label: 'Product applications' },
            { label: "Not applicable - I don't have a routine" },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_13',
        title: 'How does your scalp usually feel?',
        subheader: 'For several days of the week, my scalp feels...',
        options: [
            { label: 'Tight' },
            { label: 'Tight and itchy' },
            { label: 'Itchy and painful' },
            { label: 'Itchy' },
            { label: 'Sensitive' },
            { label: 'None of the above' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_14',
        title: 'Do you have a flaky scalp?',
        subheader: 'Select yes if you typically have flakes, but they are currently under control',
        options: [
            { label: 'Yes, rarely' },
            { label: 'Yes, usually' },
            { label: 'Yes, always' },
            { label: 'Not applicable' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_15',
        title: 'After washing, how long does it take for your scalp to become oily?',
        subheader: 'Select your answer based on how you feel several days per week or more',
        options: [
            { label: '1-2 days' },
            { label: '3-4 days' },
            { label: '5+ days' },
            { label: 'Not applicable - My scalp gets dry, not oily' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'haircare_16',
        title: 'After washing, how long does it take for your scalp to become dry?',
        subheader: 'Select your answer based on how you feel several days per week or more',
        options: [
            { label: 'Within hours' },
            { label: '1-2 days' },
            { label: '3-5 days' },
            { label: 'Not applicable - My hair gets oily, not dry' },
        ],
        type: 'question',
        choice: 'radio',
    },
];
const preferenceQuestions: Question[] = [
    {
        id: 'preference_1',
        title: 'Do you have specific product ingredient preferences?',
        subheader: 'Select one answer',
        options: [
            { label: 'Vegan' },
            { label: 'Protein free' },
            { label: 'Silicone free' },
            { label: 'Nut free' },
            { label: 'No preferences' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_2',
        title: 'Share why you have chosen ingredient preferences, if selected',
        subheader: '',
        options: [],
        type: 'question',
        choice: 'input',
    },
    {
        id: 'preference_3',
        title: 'Rate your desire to solve your problem or achieve your goal',
        subheader: 'Select one  answer',
        options: [
            { label: 'High' },
            { label: 'Medium' },
            { label: 'Low' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_4',
        title: 'How significant are your current hair care needs to you?',
        subheader: 'Select one  answer',
        options: [
            { label: 'Very important' },
            { label: 'Somewhat important' },
            { label: 'Not important' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_5',
        title: 'What is your age range?',
        options: [
            { label: '18-24' },
            { label: '25-34' },
            { label: '35-40' },
            { label: '40-50' },
            { label: '60+' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_6',
        title: 'Gender',
        options: [
            { label: 'Male' },
            { label: 'Female' },
            { label: 'Non - binary' },
            { label: 'Transgender' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_7',
        title: 'Race & heritage',
        options: [
            { label: 'Black african carribean' },
            { label: 'Mixed black and white' },
            { label: 'Latin' },
            { label: 'South asian' },
            { label: 'East asian' },
            { label: 'White' },
            { label: 'Middle eastern' },
            { label: 'Mixed other' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_8',
        title: 'Where do you live?',
        subheader: 'To assess water quality in your area',
        options: [
            { label: 'North London' },
            { label: 'East London' },
            { label: 'Latin' },
            { label: 'South London' },
            { label: 'West London' },
            { label: 'Home Counties' },
            { label: 'Other' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_9',
        title: 'If outside Greater London, select your county',
        subheader: 'To assess water quality in your area',
        options: [
            { label: 'Yorkshire' },
            { label: 'West Midlands' },
            { label: 'Nottinghamshire' },
            { label: 'Avon & Somerset' },
            { label: 'Merseyside' },
            { label: 'Leicestershire' },
            { label: 'Essex' },
            { label: 'Middlesex' },
            { label: 'Lancashire' },
            { label: 'Kent' },
            { label: 'Surrey' },
            { label: 'Other' },
        ],
        type: 'question',
        choice: 'radio',
    },
    {
        id: 'preference_10',
        title: 'If other, please state your country or location',
        options: [],
        type: 'question',
        choice: 'input',
    },
    {
        id: 'preference_11',
        title: 'In the next 2 months, Which postcode will you frequent the most?',
        subheader: 'Your postcode informs us about your hairs daily environment',
        options: [],
        type: 'question',
        choice: 'input'
    },
];

const titleMap: { [key: number]: string; } = {
    0: 'Your Hair, Your Goals',
    1: 'Your Hair, Your Goals',
    2: 'Upload Pictures',
    3: 'Extra Information',
    4: 'Medical Conditions',
    5: 'Lifestyle',
    6: 'Hair Care',
    7: 'A little about you',
};

const subTitleMap: { [key: number]: string; } = {
    0: 'The following questions will help us to get a picture of you!',
    1: 'The following questions will help us to get a picture of you!',
    2: 'Your x3 images should be clear & high quality !',
    3: 'Please share any relevant information and observations about your hair E.g. Multiple hair types, Problems,  Reactions, Behaviour, treatment history, etc',
    4: 'The following questions will help us to get a picture of you!',
    5: 'The following questions will help us to get a picture of you!',
    6: 'The following questions will help us to get a picture of you!',
    7: 'The following questions will help us to get a picture of you!',
};

const Quiz = () => {
    const [index, setIndex] = useState(0);
    const [answers, setAnswers] = useState<Answer>({});

    const handleSetAnswer = (questionId: string, answer: string) => {
        setAnswers(prev => ({ ...prev, [questionId]: answer }));
    };

    const handleIndex = useCallback((action: string) => setIndex(prev => action === 'back' ? (prev - 1) : (prev + 1)), [setIndex]);

    const RenderView = useMemo(() => {
        switch (index) {
            case 0:
                return <Landing onStartQuiz={() => setIndex(1)} />;
            case 1:
                return <General questions={initialQuestions} answers={answers} setAnswer={handleSetAnswer}
                    onAction={handleIndex} />;
            case 2:
                return <UploadPhotos answers={answers} setAnswer={handleSetAnswer} onAction={handleIndex} />;
            case 3:
                return <ExtraInformation answers={answers} setAnswer={handleSetAnswer} onAction={handleIndex} />;
            case 4:
                return <MedicalConditions questions={medicalQuestions} answers={answers} setAnswer={handleSetAnswer}
                    onAction={handleIndex} />;
            case 5:
                return <Lifestyle questions={lifestyleQuestions} answers={answers} setAnswer={handleSetAnswer}
                    onAction={handleIndex} />;
            case 6:
                return <HairCare questions={haircareQuestions} answers={answers} setAnswer={handleSetAnswer}
                    onAction={handleIndex} />;
            case 7:
                return <Preferences questions={preferenceQuestions} answers={answers} setAnswer={handleSetAnswer}
                    onAction={handleIndex} />;
            case 8:
                return <AnalysingResults answers={answers} setAnswer={handleSetAnswer} onAction={handleIndex} />;
            case 9:
                return <Results answers={answers} setAnswer={handleSetAnswer} />;
            default:
                return null;
        }
    }, [index, answers, handleIndex]);

    const progress = useMemo(() => {
        const totalQuestions = initialQuestions.length + medicalQuestions.length + lifestyleQuestions.length + haircareQuestions.length + preferenceQuestions.length;
        const answerEntries = Object.values(answers).length;

        const percentage = (answerEntries / totalQuestions) * 100;
        return percentage;
    }, [answers]);

    const title = useMemo(() => titleMap[index], [index]);
    const subTitle = useMemo(() => subTitleMap[index], [index]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (index === 8) {
            setTimeout(() => {
                setIndex(9);
            }, 5000);
        }
    }, [index]);

    // const handleExit = () => {
    //     window.location.href = '/';
    // };

    return (
        <Container className={`d-flex flex-column p-3 mt-5 h-100`}>
            <QuizHeader />
            {RenderView}
            {
                (index !== 0) && (index !== 8) && (index !== 9) &&
                // <Container className={`d-flex justify-content-between me-auto p-3 z-3 position-relativex bottom-0  start-0  w-100 sticky-bottom`}>
                <Container className={`mt-5  mb-5 position-sticky absolute start-0 top-0`}>
                    <Row className={`justify-content-evenly  align-items-center`}>
                        {/* <Row className={`bg-dark text-light  p-2 rounded mb-2`}> */}
                        <small className={`fs-6 fw-bold bg-dark text-light p-2 rounded`}>
                            <span className={`fw-bolder fs-5`}>{title} {' '} </span>
                            {subTitle}
                        </small>
                    </Row>
                    <Row className={`bg-light text-dark`}>
                        {/*<ProgressHeaderIndex>*/}
                        <Row className={`justify-content-evenly`}>
                            <ProgressHeaderTab
                                style={{ backgroundColor: index <= 3 ? colors.LIGHTGRAY.pastel : undefined }}>
                                <span className={`fw-bolder`}>Hair Details</span>
                            </ProgressHeaderTab>
                            <ProgressHeaderTab
                                style={{ backgroundColor: index === 4 ? colors.LIGHTGRAY.pastel : undefined }}>
                                <span className={`fw-bolder`}>Medical</span>
                            </ProgressHeaderTab>
                            <ProgressHeaderTab
                                style={{ backgroundColor: index === 5 ? colors.LIGHTGRAY.pastel : undefined }}>
                                <span className={`fw-bolder`}>Lifestyle</span>
                            </ProgressHeaderTab>
                            <ProgressHeaderTab
                                style={{ backgroundColor: index >= 6 ? colors.LIGHTGRAY.pastel : undefined }}>
                                <span className={`fw-bolder`}>Hair Care</span>
                            </ProgressHeaderTab>
                        </Row>
                        <ProgressBar>
                            <div style={{ width: `${progress}%`, padding: '3px' }}>
                                <span>{Math.round(progress)}%</span>
                            </div>
                        </ProgressBar>
                    </Row>
                </Container>
            }

        </Container >
    );
};

export default Quiz;
